const SnsShare = (array) => {
  array.forEach(element => {
    const title = document.title+'\n'
    const text = element.dataset.text+'\n'
    const href = element.href
    const snsTitle = encodeURIComponent(title)
    const snsText = encodeURIComponent(text)
    const snsUrl = encodeURIComponent(href)
    switch (element.dataset.id) {
      case 'line':
        element.setAttribute('href', 'http://line.me/R/msg/text/?'+snsTitle+snsText+'%20'+snsUrl);
        break;
      case 'facebook':
        element.setAttribute('href', 'http://www.facebook.com/sharer.php?u='+snsUrl);
        break;
      case 'twitter':
        element.setAttribute('href', 'http://twitter.com/share?text='+snsTitle+snsText+'&url='+''+snsUrl);
        break;
    }
  });
}
export default SnsShare;