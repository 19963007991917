<template lang="pug">
header.header(v-if="$store.state.page !== 'top'")
  nav.nav
    ul.nav__list(v-if="$store.state.navColor")
      li.nav__item(@click="About()")
        img(src="/assets/img/nav_about_white.svg", alt="about")
      //- li.nav__item
      //-   img(src="/assets/img/nav_lang_white.svg", alt="lang")
      li.nav__item(@click="Sound()")
        img(v-if="$store.state.sound" src="/assets/img/nav_sound_white_on.svg", alt="sound")
        img(v-else src="/assets/img/nav_sound_white_off.svg", alt="sound")
      li.nav__item.item--menu(@click="Menu()")
        img(v-if="$store.state.menu" src="/assets/img/nav_menu_white_close.svg", alt="menu")
        img(v-else src="/assets/img/nav_menu_white_open.svg", alt="menu")
    ul.nav__list(v-else)
      li.nav__item(@click="About()")
        img(src="/assets/img/nav_about_black.svg", alt="about")
      //- li.nav__item
      //-   img(src="/assets/img/nav_lang_black.svg", alt="lang")
      li.nav__item(@click="Sound()")
        img(v-if="$store.state.sound" src="/assets/img/nav_sound_black_on.svg", alt="sound")
        img(v-else src="/assets/img/nav_sound_black_off.svg", alt="sound")
      li.nav__item.item--menu(@click="Menu()")
        img(v-if="$store.state.menu" src="/assets/img/nav_menu_black_close.svg", alt="menu")
        img(v-else src="/assets/img/nav_menu_black_open.svg", alt="menu")

.about(:class="{'is-active': $store.state.about}")
  .about__texture
  .about__image
    ul.image__list
      li.list__item.ball_1
        img.list__ball(src="/assets/img/about_img_ball_01.png", alt="ABOUT COURT SIDE" width="792" height="785")
      li.list__item.ball_2
        img.list__ball(src="/assets/img/about_img_ball_02.png", alt="ABOUT COURT SIDE" width="792" height="785")
      li.list__item.ball_3
        img.list__ball(src="/assets/img/about_img_ball_03.png", alt="ABOUT COURT SIDE" width="792" height="785")
      li.list__item.ball_4
        img.list__ball(src="/assets/img/about_img_ball_04.png", alt="ABOUT COURT SIDE" width="792" height="785")
      li.list__item.ball_5
        img.list__ball(src="/assets/img/about_img_ball_05.png", alt="ABOUT COURT SIDE" width="792" height="785")
      li.list__item.ball_6
        img.list__ball(src="/assets/img/about_img_ball_06.png", alt="ABOUT COURT SIDE" width="792" height="785")
    img.image__heading(ref="aboutHeading" src="/assets/img/about_img_heading.svg", alt="" width="168" height="74")
    picture
      source(media="(max-width: 750px)", srcset="/assets/img/about_img_txt_sp.svg")
      img.image__text(ref="aboutText" src="/assets/img/about_img_txt.svg", alt="")
  .about__close(@click="About()")
    | CLOSE
  footer.about__footer
    a.footer__logo(href="https://slamdunk-movie.jp/", target="_blank")
      img(src="/assets/img/about_footer_logo.svg", width="79" height="70", alt="SLAM DUNK")
    img.footer__cs(src="/assets/img/about_footer_cs.svg", width="62" height="15", alt="COURT SIDE")
    img.footer__share(src="/assets/img/about_footer_share.svg", width="27" height="13", alt="SHARE")
    ul.footer__sns
      li.sns__item
        a.sns__link.sns(data-id="line" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/about_sns_icon_line.svg", alt="line")
      li.sns__item
        a.sns__link.sns(data-id="facebook" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/about_sns_icon_facebook.svg", alt="facebook")
      li.sns__item
        a.sns__link.sns(data-id="twitter" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/about_sns_icon_twitter.svg", alt="twitter")
    img.footer__copyright(src="/assets/img/about_copyright.svg", width="123" height="24" alt="copyright")

.menu.menu--interview(:class="{'is-active': $store.state.menuInterview}")
  .texture
  .menu__wrap
    a(href="https://slamdunk-movie.jp/", target="_blank")
      img.menu__logo(src="/assets/img/menu_logo_white.svg", width="158" height="139", alt="SLAM SUNK")
    img.menu__cs(@click="PostReturnTop" data-menu="interview" src="/assets/img/menu_cs_white.svg", alt="COURT SIDE")
    img.menu__share(src="/assets/img/menu_share_white.svg", alt="SHARE")
    ul.menu__sns
      li.sns__item
        a.sns__link.sns(data-id="line" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/menu_sns_line_white.svg", alt="line")
      li.sns__item
        a.sns__link.sns(data-id="facebook" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/menu_sns_facebook_white.svg", alt="facebook")
      li.sns__item
        a.sns__link.sns(data-id="twitter" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/menu_sns_twitter_white.svg", alt="twitter")
    p.menu__voices VOICES
    ul.menu__voice
      li.voice__item
        a.voice__link(href="https://t.co/yqhJJJ0o6g", target="_blank")
          img(src="/assets/img/menu_voice_podcast.svg", alt="podcast")
      li.voice__item
        a.voice__link(href="https://t.co/4wl9eLyNke", target="_blank")
          img(src="/assets/img/menu_voice_youtube.svg", alt="youtube")
      li.voice__item
        a.voice__link(href="https://t.co/hRY3ucs6zA", target="_blank")
          img(src="/assets/img/menu_voice_spotify.svg", alt="spotify")
    img.menu__copyright(src="/assets/img/menu_copyright_white.svg", alt="copyright")

.menu.menu--message(:class="{'is-active': $store.state.menuMessage }")
  .texture
  .menu__wrap
    a(href="https://slamdunk-movie.jp/", target="_blank")
      img.menu__logo(src="/assets/img/menu_logo_black.svg", width="158" height="139", alt="SLAM SUNK")
    img.menu__cs(@click="PostReturnTop" data-menu="message" src="/assets/img/menu_cs_black.svg", alt="COURT SIDE")
    img.menu__share(src="/assets/img/menu_share_black.svg", alt="SHARE")
    ul.menu__sns
      li.sns__item
        a.sns__link.sns(data-id="line" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/menu_sns_line_black.svg", alt="line")
      li.sns__item
        a.sns__link.sns(data-id="facebook" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/menu_sns_facebook_black.svg", alt="facebook")
      li.sns__item
        a.sns__link.sns(data-id="twitter" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/menu_sns_twitter_black.svg", alt="twitter")
    img.menu__copyright(src="/assets/img/menu_copyright_black.svg", alt="copyright")

.menu.menu--about(:class="{'is-active': $store.state.menuAbout}")
  .about__texture
  .menu__wrap
    a(href="https://slamdunk-movie.jp/", target="_blank")
      img.menu__logo(src="/assets/img/menu_logo_white.svg", width="158" height="139", alt="SLAM SUNK")
    img.menu__cs(@click="PostReturnTop" data-menu="about" src="/assets/img/menu_cs_white.svg", alt="COURT SIDE")
    img.menu__share(src="/assets/img/menu_share_white.svg", alt="SHARE")
    ul.menu__sns
      li.sns__item
        a.sns__link.sns(data-id="line" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/menu_sns_line_white.svg", alt="line")
      li.sns__item
        a.sns__link.sns(data-id="facebook" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/menu_sns_facebook_white.svg", alt="facebook")
      li.sns__item
        a.sns__link.sns(data-id="twitter" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
          img(src="/assets/img/menu_sns_twitter_white.svg", alt="twitter")
</template>

<script>
import UA from '@/module/userAgent'
import gsap from 'gsap'

const easeOutBack = (x) => {
  const c1 = 5;
  const c3 = c1 + 1;

  return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
}

export default {
  data(){
    return{
      aboutBallCurrent: 0,
      aboutBallCurrentMax: 6,
      animeTime: 2,
      animCount: 7,
      time: 0,
      timeMax: 0,
      stepTime: 0,
      stepMax: 0,
      step: 0,
      scale: 0,
      alpha: 0,
    }
  },
  emits: ['postReturnTop'],
  mounted(){
    // ナビゲーションカラー
    if(UA.IS_WIN == 0) this.$store.state.navColor = true
  },
  watch:{
  },
  computed:{
  },
  methods:{
    Sound(){
      if(!this.$store.state.audio){
        this.$store.state.audio = new Audio('/assets/sound.mp3')
        this.$store.state.audio.loop = true
      }
      this.$store.state.sound = !this.$store.state.sound
      switch (this.$store.state.sound) {
        case true:
          this.$store.state.audio.play()
          break
        case false:
          this.$store.state.audio.pause()
          break
      }
    },
    Menu(){
      if(UA.IS_WIN == 1) {
        this.$store.state.menu = !this.$store.state.menu
        switch (this.$store.state.page) {
          case 'message':
            this.$store.state.menuMessage = !this.$store.state.menuMessage
            break
          case 'interview':
            this.$store.state.menuInterview = !this.$store.state.menuInterview
            break
          case 'about':
            this.$store.state.menuAbout = !this.$store.state.menuAbout
            break
        }
      }
    },
    LoopStart(count, sec){
      this.time = 0
      this.timeMax = sec * 1000
      this.stepTime = this.timeMax / count
      this.stepMax = count

      setTimeout(this.LoopAnime, this.stepTime)

      const animeDelay = this.animeTime + 0.4
      this.aboutHeadingAnime = gsap.to(this.$refs.aboutHeading,{
          delay: animeDelay,
          duration: 1,
          alpha: 1,
        })
      this.aboutTextAnime = gsap.to(this.$refs.aboutText,{
        delay: animeDelay,
        duration: 1,
        alpha: 1,
      })
    },
    LoopAnime(){
      const easeValue = easeOutBack(this.time / this.timeMax)

      this.step = Math.round(easeValue * this.stepMax)
      this.scale = easeValue
      this.aboutBallCurrent = Math.round(this.step) % this.aboutBallCurrentMax

      for (let index = 0; index < this.aboutBallCurrentMax; index++) {
        this.alpha = (index == this.aboutBallCurrent) ? 1.0 : 0.0
        gsap.set('.ball_'+(index + 1),{
          alpha: this.alpha,
          scale: this.scale
        })
      }

      this.time += this.stepTime

      if(this.time < this.timeMax){
        this.LoopTimeOut = setTimeout(this.LoopAnime, this.stepTime)
      }
      // else{
      //   this.LoopStart(this.animCount * this.aboutBallCurrentMax + 1, this.animeTime)
      // }
    },
    About(){
      this.$store.state.about = !this.$store.state.about

      if(this.$store.state.about){
        this.LoopStart(this.animCount * this.aboutBallCurrentMax + 1, this.animeTime)
      } else{
        this.aboutHeadingAnime.paused(true);
        this.aboutTextAnime.paused(true);

        gsap.set(this.$refs.aboutHeading,{
          duration: 0.7,
          alpha: 0,
        })
        gsap.set(this.$refs.aboutText,{
          duration: 0.7,
          alpha: 0,
        })
        for (let index = 0; index < this.aboutBallCurrentMax; index++) {
          gsap.set('.ball_'+(index + 1),{
            duration: 0.7,
            alpha: 0
          })
        }

        clearTimeout(this.LoopTimeOut)
      }

      if(UA.IS_WIN == 1){
        if(!this.$store.state.about){
          if(this.$route.path == '' || this.$route.path == '/'){
            this.$store.state.navColor = false
          }else if(this.$route.path == '/message' || this.$route.path == '/message/'){
            this.$store.state.navColor = false
          }else if(this.$route.path == '/interview' || this.$route.path == '/interview/'){
            this.$store.state.navColor = true
          }else{
            for (let index = 0; index < this.interviewData.length; index++) {
              if(this.$route.path == this.interviewData[index].link || this.$route.path == this.interviewData[index].link+'/'){
                this.$store.state.navColor = true
              }
            }
          }

          if(this.$store.state.menuAbout) {
            this.$store.state.menuAbout = !this.$store.state.menuAbout
            this.$store.state.menu = !this.$store.state.menu
          }
        }else{
          this.$store.state.navColor = true
        }
      }

      if(this.$store.state.about){
        this.$store.state.page = 'about'
        if(this.$store.state.menu){
          this.MenuAllClose()
        }
      }else{
        let path = this.$route.path.slice(1)
        if(path[path.length - 1] == '/'){
          path = path.slice(0, -1)
        }
        this.$store.state.page = path
      }
    },
    Reload(){
      history.replaceState('','','/')
      this.$route.path = '/'
      location.reload()
    },
    PostReturnTop(){
      if(UA.IS_WIN == 1) {
        this.$store.state.page = 'top'
        this.$store.state.court = true
        this.MenuAllClose()
        this.$emit('postReturnTop')
      }
    },
    MenuAllClose(){
      if(this.$store.state.menuAbout) this.$store.state.about = false
      this.$store.state.menu = false
      this.$store.state.menuMessage = false
      this.$store.state.menuInterview = false
      this.$store.state.menuAbout = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 16px;
  right: 32px;
  z-index: 99;
  @include mq('sp'){
    top: 60px;
    right: 22px;
  }
  .nav{

  }
  .nav__list{

  }
  .nav__item{
    display: inline-block;
    margin-right: 14px;
    cursor: pointer;
    &:last-child{
      margin-right: 0;
    }
  }
  .item--menu{
    @include mq('pc'){
      display: none;
    }
    @include mq('sp'){
      display: inline-block;
    }
  }
}
.about{
  @include transition(700);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 3;
  pointer-events: none;
  opacity: 0;
  .about__image{
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 61.875vw;
    max-width: 792px;
    will-change: opacity, transform;
    @include mq('sp'){
      top: 50%;
      margin: 0 -9.6560846561vw 0 -6.656085vw;
      width: calc(100vw + 19.312169312169312vw);
      max-width: none;
      transform: translateY(-50%);
    }
    .image__list{
      width: 100%;
    }
    .list__item{
      transform: scale(0);
    }
    .list__ball{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 100%;
      height: auto;
    }
    .image__heading{
      position: absolute;
      top: -38vw;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 21.158690176322418%;
      opacity: 0;
      @media screen and (min-width: 1281px) {
        top: -486px;
      }
      @include mq('sp'){
        top: -74vw;
        right: 3%;
        width: 30%;
      }
    }
    .image__text{
      position: absolute;
      top: -4vw;
      right: -21%;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 62.247474747474747%;
      opacity: 0;
      @media screen and (min-width: 1281px) {
        top: -52px;
      }
      @include mq('sp'){
        top: 12vw;
        right: -8%;
        width: 70%;
      }
    }
  }

  .about__close{
    @include font('bebas-neue-pro');
    position: absolute;
    top: 0;
    bottom: 0;
    left: 34px;
    margin: auto 0;
    height: 1em;
    color: #DBD6D0;
    font-size: 1.5rem;
    letter-spacing: 0.03em;
    cursor: pointer;
    @include mq('sp'){
      top: 64px;
      left: 24px;
      bottom: auto;
      font-size: 1.3rem;
    }
    &::after{
      content: "";
      display: inline-block;
      position: relative;
      bottom: 2px;
      margin-left: 8px;
      width: 18px;
      height: 18px;
      background-image: url(/assets/img/about_close.svg);
      vertical-align: middle;
    }
  }

  .about__footer{
    position: absolute;
    right: 34px;
    bottom: 20px;
    @include mq('sp'){
      display: none;
    }
    .footer__logo{
      display: block;
      margin: 0 0 28px auto;
      width: 79px;
    }
    .footer__cs{
      display: block;
      margin: 0 0 6px auto;
    }
    .footer__share{
      display: block;
      margin: 0 0 4px auto;
    }
    .footer__sns{
      display: flex;
      justify-content: space-between;
      margin: 0 0 12px auto;
      width: 79px;
    }
  }
}
.about__texture{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/assets/img/about_texture.png);
  background-repeat: repeat;
  background-size: cover;
  pointer-events: none;
}
.about.is-active{
  pointer-events: auto;
  opacity: 1;
}

.menu{
  @include transition();
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  @include mq("pc"){
    display: none;
  }
  .menu__wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    margin-top: 30px;
    width: 100%;
    height: 100%;
  }
  .menu__logo{
    margin-bottom: 44px;
  }
  .menu__cs{
    margin-bottom: 16px;
  }
  .menu__share{
    margin-bottom: 12px;
  }
  .menu__sns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    width: 134px;
  }
  .sns__item{
  }
  .sns__link{
  }
  .menu__voices{
    @include font('neue-haas-grotesk-display');
    margin-bottom: 12px;
    text-align: center;
    color: #fff;
    font-size: 1.1rem;
  }
  .menu__voice{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 130px;
  }
}
.menu--interview{
  background-color: #000;
}
.menu--message{
  background-color: #FFFDF4;
}
.menu--about{
  background-color: #000;
}
.menu.is-active{
  pointer-events: auto;
  opacity: 1;
}
</style>x