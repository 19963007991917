<template lang="pug">
.itv__tag.tag
  h2.tag__heading
    img(src="/assets/img/tag_heading.png", alt="from court side" width="737" height="170")
  .tag__wrap.wrap
    h3.category__heading
      span #&nbsp;
      | {{ $store.state.itvTagCategory }}
    .tag__contents
      figure.tag__card.card(v-for="data in buildData" :data-index="data.index" :data-link="data.link" :data-bg="data.image.bg" @click="SelectArticle")
        img.card__image(:src="data.image.main", alt="")
        p.card__data.data
          span.data__id
            | INTERVIEW&nbsp;
            span #
            | {{data.id}}&nbsp;&nbsp;
          time.data__time {{ data.date }}
        figcaption.card__caption.caption
          span.caption__main {{ data.title }}
          span.caption__sub {{ data.interviee }}

    .tag__pager.pager
      .pager__button.prev(data-button="prev" :class="{'is-active': pagerButtonPrev}" @click="PagerButton($event.target.dataset.button)")
      .pager__button.next(data-button="next" :class="{'is-active': pagerButtonNext}" @click="PagerButton($event.target.dataset.button)")
      .pager__wrap
        ul.pager__list.list(ref="pagerList")
          li.list__item(v-for="data, index in this.sortData" :data-index="index" ref="pagerItem" @click="FocusPagination($event.target.dataset.index)")
            | {{ index + 1 }}

    .tag__returnInterviewTop.returnInterviewTop(@click="ReturnInterviewTop()")
      img.returnInterviewTop__arrow(src="/assets/img/tag_return_arrow.svg", alt="")
      span.returnInterviewTop__text INTERVIEW TOP

    .tag__category.category
      ul.category__list.list
        li.list__item(v-for="data in this.sortListData" :data-tag="data" @click="SelectCategory($event.target.dataset.tag)")
          span #&nbsp;
          | {{ data }}
</template>

<script>
import UA from '@/module/userAgent'

export default {
  data(){
    return{
      sortData: [],
      sortListData: [],
      buildData: [],
      num: 9,
      index: 0,
      range: 2,
      trans: 0,
      duration: 300,
      easing: 'ease-out',
      pagerButtonPrev: false,
      pagerButtonNext: false,
      pagerFont: [18, 18],
      pagerMargin: [20, 20],
    }
  },
  created(){
    // データの順番逆
    this.$store.state.interviewData.reverse()

    // 絞り込み
    this.Sort(this.$store.state.itvTagCategory)

    // タグ一覧データ
    this.TagList()
  },
  mounted(){
    // pager初期化
    this.PagerInit()
  },
  watch:{
  },
  computed:{
  },
  methods:{
    Sort(category){
      this.$store.state.itvTagCategory = category

      this.sortData = []
      let sort = []

      this.$store.state.interviewData.forEach(data => {
        for (let index = 0; index < data.tag.length; index++) {
          switch (category) {
            case 'ALL':
              sort = Object.values(this.$store.state.interviewData)
              break;
            case data.tag[index]:
              sort.push(data)
              break;
          }
        }
      })

      while (sort.length > 0) {
        this.sortData.push(sort.splice(0, this.num))
      }

      this.Build()
    },
    Build(){
      this.buildData = this.sortData[this.index]
    },
    TagList(){
      let allData = []
      this.$store.state.interviewData.forEach(data => {
        for (let index = 0; index < data.tag.length; index++) {
          allData.push(data.tag[index])
        }
      })
      this.sortListData = Array.from(new Set(allData))
      this.sortListData.unshift('ALL')
    },
    SelectCategory(category){
      this.index = 0
      this.trans = 0

      this.Sort(category)
      this.PagerInit()
    },
    PagerInit(){
      this.$nextTick(() => {
        this.$refs.pagerItem.forEach(element => {
          element.classList.remove('is-active')
        })
        this.$refs.pagerItem[this.index].classList.add('is-active')

        for (let index = 0; index < this.$refs.pagerItem.length; index++) {
          this.$refs.pagerItem[index].textContent = this.$refs.pagerItem[index].textContent.padStart(2, '0')
        }

        this.PagerButton('')
      })
    },
    FocusPagination(index){
      this.index = Number(index)

      this.$refs.pagerItem.forEach(element => {
        element.classList.remove('is-active')
      })
      this.$refs.pagerItem[this.index].classList.add('is-active')

      this.Build()
    },
    PagerButton(button){
      if(button == 'next'){
        this.trans++
      }else if(button == 'prev'){
        this.trans--
      }

      if(this.trans + this.range > this.range){
        this.pagerButtonPrev = true
      }else{
        this.pagerButtonPrev = false
        this.pagerButtonNext = false
      }

      if(this.trans + this.range < this.sortData.length - 1){
        this.pagerButtonNext = true
      }else{
        this.pagerButtonNext = false
      }

      this.pagerTrans()
    },
    pagerTrans(){
      this.$refs.pagerList.style.transitionDuration = this.duration+'ms'
      this.$refs.pagerList.style.transitionTimingFunction = this.easing
      this.$refs.pagerList.style.transform = 'translate3d(calc((-'+this.pagerFont[UA.IS_WIN]+'px * '+this.trans+') + (-'+this.pagerMargin[UA.IS_WIN]+'px * '+this.trans+')), 0, 0)'
    },
    PagerActive(){
    },
    SelectArticle(e){
      this.$store.state.current = Number(e.currentTarget.dataset.index) + 1

      const topBg = document.querySelector('.itv__bg')
      topBg.style.backgroundImage = 'url(' + e.currentTarget.dataset.bg + ')'

      this.$store.state.accordion = false

      this.$store.state.itvPage = true
    },
    ReturnInterviewTop(){
      this.$store.state.itvPage = true
      this.$store.state.accordion = true
    },
  }
}
</script>

<style lang="scss" scoped>
.tag{
  position: relative;
  .tag__heading{
    position: fixed;
    top: 50px;
    left: 300px;
    @include mq("sp"){
      top: 90px;
      right: 0;
      left: 0;
      margin: 0 auto;
      text-align: center;
    }
    img{
      @include mq("sp"){
        width: 100%;
        max-width: 324px;
        height: auto;
      }
    }
  }
  .tag__wrap.wrap{
    position: relative;
    padding: 106px 108px 0;
    width: 100%;
    text-align: center;
    z-index: 1;
    @include mq("sp"){
      padding: 74px 42px 0;
    }
  }
  .category__heading{
    display: inline-block;
    position: relative;
    margin: 0 auto 40px;
    padding-bottom: 4px;
    border-bottom: solid 2px #FF5252;
    color: #DBD6D0;
    font-size: 2.4rem;
    @include font('NotoSansJP-Regular');
    @include mq('sp'){
      margin: 0 auto 70px;
      font-size: 2rem;
    }
  }
  .tag__contents{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include mq('sp'){
      display: block;
    }
  }
  .tag__card.card{
    margin-top: 44px;
    margin-right: 76px;
    width: calc(33.3333333333% - (76px * 2 / 3));
    text-align: left;
    cursor: pointer;
    @include mq('sp'){
      width: 100%;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 48px;
    }
    .card__image{
      width: 100%;
    }
    .card__data.data{
      @include font("bebas-neue-pro_italic");
      margin-top: 14px;
      font-size: 1.2rem;
      color: #83827F;
      @include mq('sp'){
        margin-top: 12px;
        font-size: 1.1rem;
      }
      .data__id{

      }
      .data__time{

      }
    }
    .card__caption.caption{
      color: #DBD6D0;
      .caption__main{
        @include font("NotoSansJP-Medium");
        display: block;
        font-size: 1.4rem;
        line-height: 2;
      }
      .caption__sub{
        @include font("NotoSansJP-Regular");
        display: block;
        font-size: 1.2rem;
        line-height: 1.5;
      }
    }
  }
  .tag__card:nth-of-type(-n+3){
    margin-top: 0;
  }
  .tag__card:nth-of-type(3n){
    margin-right: 0;
  }
  .tag__card:last-child{
    @include mq('sp'){
      margin-bottom: 0;
    }
  }

  .tag__pager.pager{
    position: relative;
    margin-top: 40px;
    width: calc((18px * 3) + (20px * 2));
    @include mq('sp'){
      margin-top: 48px;
    }
    .pager__button{
      pointer-events: none;
    }
    .pager__button:before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 14px;
      height: 6px;
      background-repeat: no-repeat;
    }
    .pager__button.prev:before {
      background-image: url("/assets/img/pager_arrow_prev.svg");
    }
    .pager__button.next:before{
      background-image: url("/assets/img/pager_arrow_next.svg");
    }
    .pager__button.is-active{
      pointer-events: auto;
    }
    .pager__button.prev.is-active:before{
      background-image: url("/assets/img/pager_arrow_prev_active.svg");
    }
    .pager__button.next.is-active:before{
      background-image: url("/assets/img/pager_arrow_next_active.svg");
    }
    .pager__wrap{
      overflow: hidden;
      margin: 0 auto;
      width: 100%;
    }
    .pager__list.list{
      display: flex;
      flex-wrap: nowrap;
      margin: 0 auto;
      .list__item{
        @include font("bebas-neue-pro-Middle_italic");
        position: relative;
        margin-right: 20px;
        min-width: 18px;
        text-align: center;
        line-height: 1.5;
        color: rgba($color: #DBD6D0, $alpha: 0.3);
        cursor: pointer;
      }
      .list__item.is-active{
        @include hoverLine();
        color: #DBD6D0;
      }
    }
  }
  .tag__returnInterviewTop.returnInterviewTop{
    margin-top: 20px;
    .returnInterviewTop__arrow{
      margin-right: 8px;
    }
    .returnInterviewTop__text{
      @include hoverLine();
      @include font("bebas-neue-pro_italic");
      position: relative;
      color: #DBD6D0;
      font-size: 1.5rem;
      cursor: pointer;
      &:before,&:after{
        bottom: -4px;
      }
      @include mq('sp'){
        font-size: 1.4rem;
      }
    }
  }
  .tag__category.category{
    overflow-x: auto;
    margin: 40px 0 70px;
    padding: 16px 0;
    width: 100%;
    border-top: solid #404040 1px;
    border-bottom: solid #404040 1px;
    .category__list.list{
      display: flex;
      width: 100%;
      .list__item{
        @include font("NotoSansJP-Regular");
        margin-right: 24px;
        white-space: nowrap;
        color: #DBD6D0;
        cursor: pointer;
        font-size: 1.3rem;
        @include mq('sp'){
          font-size: 1.2rem;
        }
        span{
          pointer-events: none;
        }
      }
      .list__item:last-child{
        margin-right: 0;
      }
    }
  }
}
</style>