import { createApp } from 'vue'
import { createGtm } from 'vue-gtm'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/main.scss'

createApp(App)
.use(createGtm({ id: "GTM-TJLQFH8" }))
.use(store)
.use(router)
.mount('#app')