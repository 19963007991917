<template lang="pug">
button.form__back(@click="goBack")
  img(src="/assets/img/readMore_close_arrow.svg")
  span ALL MESSAGES
</template>

<script setup>
import { useStore } from "vuex"

const store = useStore()

const goBack = () => {
  const msgOpen = document.querySelector('.msg__open')
  msgOpen.scrollTop = 0
  store.state.msgPage = true
};
</script>

<style lang="scss" scoped>
.form__back {
  @include font("bebas-neue-pro-semiexpanded-Italic");
  line-height: 18px;
  letter-spacing: 0.03em;
  width: 111px;
  height: 23px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  & > img {
    width: 12px;
    margin-right: 7px;
  }

  & > span {
    @include hoverLine();
    display: inline-block;
    text-align: center;
    position: relative;
    font-size: 15px;
    line-height: 1.8;
    width: 85px;

    @include mq("sp") {
      width: 75px;
      font-size: 13px;
    }
  }
}
</style>
