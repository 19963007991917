<template lang="pug">
.home
  Intro(@postEl="getIntroElement")
  .contents(ref="contents" :class="{'is-active': $store.state.contentsActive}")
    Header(@postReturnTop="getReturnTop")
    .court(:class="{'is-active': $store.state.court}")
      picture
        source(media="(max-width: 750px)", srcset="/assets/img/court_sp.png")
        img.court__image(src="/assets/img/court.png",  width="369" height="228", alt="")

    //- message
    .msg.window.is-top(ref="msgWindow")
      .texture
      .msg__wrapper(ref="msgWrapper")
        .msg__top.inside.top(ref="msgTop" data-id="msg" @click="Top($event)")
          .top__heading
            img(src="/assets/img/msg_top_heading.png", width="480" height="199", alt="COURT")
        .msg__open.inside.open(:class="{'page-form': $store.state.msgPage === false, 'is-active' : $store.state.msgActive}")
          .msg__bg
          h2.msg__heading
            img.heading__main(src="/assets/img/msg_heading.png", width="598" height="170")

          .msg__base(v-if="$store.state.contentsActive && $store.state.msgStart")
            Transition(name="fade" mode="out-in")
              .msg__block(v-if="$store.state.msgPage")
                MsgContents
              .msg__block(v-else-if="!$store.state.msgPage")
                MsgForm

        .msg__close.inside.close(data-id="msg" @click.self="Entry($event)")
          h2.close__heading
            img(src="/assets/img/msg_close_heading.svg", width="113" height="33", alt="message")
          footer.footer
            a.footer__logo(href="https://slamdunk-movie.jp/", target="_blank")
              img(src="/assets/img/msg_footer_logo.svg", width="79" height="70", alt="SLAM DUNK")
            img.footer__cs(@click.stop="ReturnTop()" src="/assets/img/msg_footer_cs.svg", width="79" height="19", alt="COURT SIDE")
            img.footer__share(src="/assets/img/msg_footer_share.svg", width="27" height="13", alt="SHARE")
            ul.footer__sns
              li.sns__item
                a.sns__link.sns(data-id="line" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
                  img(src="/assets/img/msg_sns_icon_line.svg", alt="line")
              li.sns__item
                a.sns__link.sns(data-id="facebook" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
                  img(src="/assets/img/msg_sns_icon_facebook.svg", alt="facebook")
              li.sns__item
                a.sns__link.sns(data-id="twitter" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
                  img(src="/assets/img/msg_sns_icon_twitter.svg", alt="twitter")
            img.footer__copyright(src="/assets/img/msg_copyright.svg", width="123" height="24", alt="copyright")

    //- interview
    .itv.window.is-top(ref="itvWindow")
      .itv__wrapper(ref="itvWrapper")
        .itv__top.inside.top(ref="itvTop" data-id="itv" @click="Top($event)")
          .top__bg(ref="topBg")
          .top__overlay
          .texture
          .top__heading
            img(src="/assets/img/itv_top_heading.png", width="312" height="198", alt="SIDE")
        .itv__open.inside.open
          .itv__bg(ref="itvBg" :class="{'is-non-active': !$store.state.itvPage}")
          .itv__overlay
          .texture

          Transition(name="fade" mode="out-in")
            .itv__block(v-if="$store.state.itvPage")
              ItvContents
            .itv__block(v-else-if="!$store.state.itvPage")
              ItvTag

        .itv__close.inside.close(data-id="itv" @click.self="Entry($event)")
          .texture
          h2.close__heading
            img(src="/assets/img/itv_close_heading.svg", width="138" height="30", alt="message")
          footer.footer
            a.footer__logo(href="https://slamdunk-movie.jp/", target="_blank")
              img(src="/assets/img/itv_footer_logo.svg", width="79" height="70", alt="SLAM DUNK")
            img.footer__cs(@click.stop="ReturnTop()" src="/assets/img/itv_footer_cs.svg", width="79" height="19", alt="COURT SIDE")
            img.footer__share(src="/assets/img/itv_footer_share.svg", width="27" height="13", alt="SHARE")
            ul.footer__sns.sns
              li.sns__item
                a.sns__link.sns(data-id="line" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
                  img(src="/assets/img/itv_sns_icon_line.svg", alt="line")
              li.sns__item
                a.sns__link.sns(data-id="facebook" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
                  img(src="/assets/img/itv_sns_icon_facebook.svg", alt="facebook")
              li.sns__item
                a.sns__link.sns(data-id="twitter" data-text="「コートの中にしか、ないものがあった。」" href="https://www.slamdunk-movie-courtside.jp", target="_blank")
                  img(src="/assets/img/itv_sns_icon_twitter.svg", alt="twitter")
            img.footer__copyright(src="/assets/img/itv_copyright.svg", width="123" height="24" alt="copyright")
</template>

<script>
import UA from '@/module/userAgent'
import SNS_SHARE from '@/module/snsShare'

// components
import Header from '@/components/header'
import Intro from '@/components/intro'
import ItvContents from '@/components/itvContents'
import ItvTag from '@/components/itvTag'
import MsgContents from '@/components/msgContents'
import MsgForm from '@/components/form/MsgForm'

export default {
  name: 'HomePage',
  components:{
    Header,
    Intro,
    ItvContents,
    ItvTag,
    MsgContents,
    MsgForm
	},
  data(){
		return{
      enterFlag: '',
      closeW: 192,
      closeH: 40,
		}
	},
  watch:{
    getData(){
      if(this.$store.state.contentsActive) return
      this.Landing()
    }
  },
  computed:{
    getData(){
      return this.$store.state.interviewData
    }
  },
	mounted(){
    this.Init()
    this.Share()
	},
  methods:{
    getIntroElement(el){
      this.introEl = el
    },
    getReturnTop(){
      const entryWindow = document.querySelector('.window.is-entry')
      entryWindow.classList.remove('is-entry')
      const windowArray = document.querySelectorAll('.window')
      windowArray.forEach(el => {
        el.classList.add('is-top')
      })
    },
    Init(){
      // リサイズ
      const sizeSet = () => {
        switch (UA.IS_WIN) {
          case 0:
            this.$refs.itvWrapper.style.width = window.innerWidth - this.closeW+'px'
            this.$refs.itvWrapper.style.height = window.innerHeight+'px'
            this.$refs.msgWrapper.style.width = window.innerWidth - this.closeW+'px'
            this.$refs.msgWrapper.style.height = window.innerHeight+'px'
            break
          case 1:
            this.$refs.itvWrapper.style.width = window.innerWidth+'px'
            this.$refs.itvWrapper.style.height = window.innerHeight - this.closeH+'px'
            this.$refs.msgWrapper.style.width = window.innerWidth+'px'
            this.$refs.msgWrapper.style.height = window.innerHeight - this.closeH+'px'

            const itvInner = document.querySelector('.itv__inner')
            itvInner.style.height = window.innerHeight - this.closeH+'px'
            this.introEl.height = window.innerHeight+'px'
            this.$refs.contents.style.height = window.innerHeight+'px'
            break
        }
      }
      sizeSet()
      window.addEventListener('resize', sizeSet)
    },
    Landing(){
      const windows = document.querySelectorAll('.window')

      if(this.$route.path == '' || this.$route.path == '/'){
        this.$store.state.page = 'top'

        if(UA.IS_WIN == 1) this.$store.state.navColor = false

        // 最新記事のインデックス格納
        this.$store.state.current = this.$store.state.interviewData.length
      }else if(this.$route.path == '/message' || this.$route.path == '/message/'){
        this.$store.state.page = 'message'

        this.$store.state.court = false

        if(UA.IS_WIN == 1) this.$store.state.navColor = false

        // top非表示
        for (let index = 0; index < windows.length; index++) {
          windows[index].classList.remove('is-top')
        }
        this.$refs.msgWindow.classList.add('is-entry')

        // 最新記事のインデックス格納
        this.$store.state.current = this.$store.state.interviewData.length

        // メッセージコンテンツ、スタート
        this.$store.state.msgStart = true
      }else if(this.$route.path == '/interview' || this.$route.path == '/interview/'){
        this.$store.state.page = 'interview'

        this.$store.state.court = false

        if(UA.IS_WIN == 1) this.$store.state.navColor = true

        // top非表示
        for (let index = 0; index < windows.length; index++) {
          windows[index].classList.remove('is-top')
        }
        this.$refs.itvWindow.classList.add('is-entry')

        // 最新記事のインデックス格納
        this.$store.state.current = this.$store.state.interviewData.length

        // スライダー設定
        this.$store.state.sliderActive = true

        // インタビュースタート
        this.$store.state.itvStart = true
      }else{
        const matchLink = this.$store.state.interviewData.find((value) => this.$route.path == value.link || this.$route.path == value.link+'/')
        if(matchLink){
          const index = Number(matchLink.index) + 1

          this.$store.state.page = 'interview'

          this.$store.state.court = false

          if(UA.IS_WIN == 1) this.$store.state.navColor = true

          // top非表示
          for (let index = 0; index < windows.length; index++) {
            windows[index].classList.remove('is-top')
          }
          this.$refs.itvWindow.classList.add('is-entry')

          // 該当記事のインデックス格納
          this.$store.state.current = index

          // スライダー設定
          this.$store.state.sliderActive = true

          // インタビュースタート
          this.$store.state.itvStart = true
          this.$store.state.itvArticleOpen = true
        }else{
          this.$store.state.page = 'top'

          if(UA.IS_WIN == 1) this.$store.state.navColor = false

          // 最新記事のインデックス格納
          this.$store.state.current = this.$store.state.interviewData.length
        }
      }

      // トップの背景指定
      this.$refs.topBg.style.backgroundImage = 'url(' + this.$store.state.interviewData[this.$store.state.interviewData.length - 1].image.top + ')'
    },
    Performance(){
      if (window.performance) {
        if (window.performance.navigation.type === 1) {
          history.replaceState('','','/')
          this.$route.path = '/'
        }
      }
    },
    Top($event){
      const windows = document.querySelectorAll('.window')
      for (let index = 0; index < windows.length; index++) {
        windows[index].classList.remove('is-top')
      }

      this.$store.state.court = false

      this.Entry($event)
    },
    ReturnTop(){
      if(UA.IS_WIN == 1) this.$store.state.navColor = false

      const windows = document.querySelectorAll('.window')
      for (let index = 0; index < windows.length; index++) {
        windows[index].classList.add('is-top')
      }
      const enter = document.querySelector('.is-entry')
      enter.classList.remove('is-entry')

      this.$store.state.court = true

      history.replaceState('','','/')
      this.$route.path = '/'
      this.$store.state.page = 'top'
    },
    Entry($event){
      this.enterFlag = $event.currentTarget.dataset.id
      switch (this.enterFlag) {
        case 'msg':
          // メッセージコンテンツスタート
          this.$store.state.msgStart = true

          if(UA.IS_WIN == 1) this.$store.state.navColor = false
          this.$refs.msgWindow.classList.add('is-entry')
          this.$refs.itvWindow.classList.remove('is-entry')

          history.replaceState('','','/message')
          this.$route.path = '/message'
          this.$store.state.page = 'message'
          break;
        case 'itv':
          // インタビューコンテンツスタート
          this.$store.state.itvStart = true

          if(UA.IS_WIN == 1) this.$store.state.navColor = true
          this.$refs.itvWindow.classList.add('is-entry')
          this.$refs.msgWindow.classList.remove('is-entry')

          this.$store.state.sliderActive = true

          if(this.$store.state.accordion){
            history.replaceState('','',this.$store.state.interviewData[this.$store.state.current - 1].link)
          }else{
            history.replaceState('','','/interview')
          }
          this.$route.path = '/interview'
          this.$store.state.page = 'interview'
          break;
      }
    },
    Share(){
      const array = document.querySelectorAll('.sns')
      SNS_SHARE(array)
    }
  }
}
</script>

<style lang="scss" scoped>
.contents{
  display: flex;
  justify-content: space-between;
  visibility: hidden;
  width: 100%;
  height: 100%;
}
.contents.is-active{
  visibility: visible;
}
.court{
  @include transition();
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 369px;
  height: 228px;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  pointer-events: none;
  @include mq("sp"){
    width: 101px;
    height: 163px;
  }
  .court__image{
    width: 100%;
    height: auto;
  }
  &.is-active{
    opacity: 1;
  }
}
.window{
  position: absolute;
  overflow: hidden;
  width: 192px;
  height: 100%;
  transition-duration: 500ms;
  transition-timing-function: $easeInOutQuart;
  will-change: width, height;
  backface-visibility: hidden;
  @include mq("sp"){
    width: 100%;
    height: $closeH;
  }
  .inside.top{
    @include transition();
    position: absolute;
    top: 0;
    width: 50vw;
    height: 100%;
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
    z-index: 1;
    @include mq("sp"){
      width: 100%;
      height: calc(50% + $closeH / 2);
    }
  }
  .inside.open{
    @include transition();
    pointer-events: none;
    opacity: 0;
  }
  .inside.close{
    @include transition();
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
  }
}
.window.is-top{
  width: 50%;
  @include mq("sp"){
    width: 100%;
    height: 50%;
  }
  .inside.top{
    pointer-events: auto;
    opacity: 1;
  }
  .inside.open{
    pointer-events: none;
    opacity: 0;
  }
  .inside.close{
    pointer-events: none;
    opacity: 0;
  }
}
.window.is-entry{
  width: calc(100% - $closeW);
  @include mq("sp"){
    width: 100%;
    height: calc(100% - $closeH);
  }
  .inside.open{
    pointer-events: auto;
    opacity: 1;
  }
  .inside.close{
    pointer-events: none;
    opacity: 0;
  }
}
.msg.window{
  left: 0;
  background-color: #FFFDF4;
  @include mq("sp"){
    top: 0;
    left: auto;
  }
}
.msg__wrapper{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.msg{
  .top__heading{
    position: absolute;
    top: 24px;
    left: 24px;
  }
}
.msg__heading{
  position: absolute;
  top: 30px;
  left: 90px;
  pointer-events: none;
  opacity: 0;
  @include mq("sp"){
    top: 100px;
    left: 24px;
  }
  .heading__main{
    display: block;
    @include mq("sp"){
      width: 245px;
      height: 65px;
    }
  }
  .heading__sub{
    display: block;
    margin-top: 24px;
    @include font("neue-haas-grotesk-display");
    @include mq("sp"){
      margin-top: 16px;
      font-size: 2.1rem;
    }
  }
}
.msg__top{
  left: 0;
  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 37px;
    bottom: 0;
    margin: auto 0;
    width: 57px;
    height: 111px;
    background-image: url(/assets/img/msg_top_arrow.svg);
    background-repeat: no-repeat;
    @include mq("sp"){
      top: 16px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 39px;
      height: 18px;
      background-image: url(/assets/img/msg_top_arrow_sp.svg);
    }
  }
  .top__heading{
    @include mq("sp"){
      position: absolute;
      top: 0;
      left: 20px;
      bottom: 50px;
      height: 97px;
      margin: auto 0;
    }
    img{
      @include mq("sp"){
        width: 227px;
        height: 97px;
      }
    }
  }
}
.msg__court{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  @include mq("sp"){
    top: auto;
    left: 0;
    margin: 0 auto;
    width: auto;
    height: 100px;
  }
}
.msg__open{
  height: 100%;
}
.msg__open.is-active{
  overflow-y: auto;
  @include mq("sp"){
    overflow-x: auto;
    overflow-y: hidden;
  }
}
.msg__open.is-active.page-form{
  @include mq("sp"){
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.msg__bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/assets/img/msg_bg.png);
  background-size: cover;
  background-position-x: 50%;
  pointer-events: none;
  @include mq("sp"){
    background-image: url(/assets/img/msg_bg_sp.png);
  }
}
.msg__base{
  height: 100%;
}
.msg__block{
  height: 100%;
}
.msg__returnTop{
  position: absolute;
  top: 24px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}

.msg__close{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: $closeW;
  height: 100%;
  @include mq("sp"){
    top: 0;
    width: 100%;
    height: $closeH;
  }
  .close__heading{
    position: relative;
    bottom: 44px;
    pointer-events: none;
    @include mq("sp"){
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: auto;
      padding: 0 20px;
      height: 100%;
      width: 100%;
    }
    &::before{
      content: "";
      display: inline-block;
      position: relative;
      bottom: 2px;
      margin-right: 16px;
      width: 12px;
      height: 25px;
      background-image: url(/assets/img/msg_close_heading_arrow.svg);
      background-repeat: no-repeat;
      vertical-align: middle;
      @include mq("sp"){
        display: none;
      }
    }
    &::after{
      content: "";
      display: inline-block;
      position: relative;
      margin-left: 6px;
      width: 11px;
      height: 10px;
      background-image: url(/assets/img/msg_close_heading_arrow_sp.svg);
      background-repeat: no-repeat;
      vertical-align: middle;
      @include mq('pc'){
        display: none;
      }
    }
    img{
      @include mq("sp"){
        width: 59px;
        height: auto;
      }
    }
  }

  .footer{
    position: absolute;
    bottom: 24px;
    @include mq("sp"){
      display: none;
    }
  }
  .footer__logo{
    display: block;
    margin: 0 auto 28px;
    width: 79px;
  }
  .footer__cs{
    display: block;
    margin: 0 auto 6px;
  }
  .footer__share{
    display: block;
    margin: 0 auto 4px;
  }
  .footer__sns{
    display: flex;
    justify-content: space-between;
    margin: 0 auto 12px;
    width: 79px;
  }
}

.itv.window{
  background-color: #000000;
  @include mq("sp"){
    bottom: 0;
    right: auto;
  }
}
.itv__returnTop{
  position: absolute;
  top: 20px;
  left: 34px;
  cursor: pointer;
  z-index: 1;
}
.itv__top{
  right: 0;
  &:after{
    content: "";
    position: absolute;
    top: 0;
    right: 37px;
    bottom: 0;
    margin: auto 0;
    width: 57px;
    height: 111px;
    background-image: url(/assets/img/itv_top_arrow.svg);
    background-repeat: no-repeat;
    @include mq("sp"){
      top: auto;
      left: 0;
      right: 0;
      bottom: 16px;
      margin: 0 auto;
      width: 39px;
      height: 19px;
      background-image: url(/assets/img/itv_top_arrow_sp.svg);
    }
  }
  .top__bg{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .top__overlay{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
  }
  .top__heading{
    position: absolute;
    right: 24px;
    bottom: 24px;
    @include mq("sp"){
      top: 50px;
      right: 20px;
      bottom: 0px;
      height: 98px;
      margin: auto 0;
    }
    img{
      @include mq("sp"){
        width: 153px;
        height: 98px;
      }
    }
  }
}
.itv{
  right: 0;
}
.itv__court{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  @include mq("sp"){
    bottom: auto;
    margin: 0 auto;
    right: 0;
    width: auto;
    height: 100px;
  }
}
.itv__wrapper{
  position: absolute;
  right: 0;
}
.itv__open{
  width: 100%;
  height: 100%;
}
.itv__bg{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  opacity: 0;
}
.itv__bg.is-non-active{
  opacity: 0 !important;
}
.itv__overlay{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.8);
}
.itv__block{
  overflow-y: auto;
  height: 100%;
}
.itv__close{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: $closeW;
  height: 100%;
  @include mq("sp"){
    width: 100%;
    height: $closeH;
  }
  .close__heading{
    position: relative;
    bottom: 44px;
    pointer-events: none;
    @include mq("sp"){
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      bottom: auto;
      padding: 0 16px;
      width: 100%;
      height: 100%;
      background-color: #141414;
    }
    &::after{
      content: "";
      display: inline-block;
      position: relative;
      bottom: 2px;
      margin-left: 16px;
      width: 14px;
      height: 25px;
      background-image: url(/assets/img/itv_close_heading_arrow.svg);
      background-repeat: no-repeat;
      vertical-align: middle;
      @include mq("sp"){
        bottom: 0;
        margin-left: 8px;
        width: 11px;
        height: 10px;
        background-image: url(/assets/img/itv_close_heading_arrow_sp.svg);
      }
    }
    img{
      @include mq("sp"){
        width: 80px;
      }
    }
  }
  .footer{
    position: absolute;
    bottom: 24px;
    @include mq("sp"){
      display: none;
    }
  }
  .footer__logo{
    display: block;
    margin: 0 auto 28px;
    width: 79px;
  }
  .footer__cs{
    display: block;
    margin: 0 auto 6px;
  }
  .footer__share{
    display: block;
    margin: 0 auto 4px;
  }
  .footer__sns{
    display: flex;
    justify-content: space-between;
    margin: 0 auto 12px;
    width: 79px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;

}
</style>
