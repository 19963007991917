<template lang="pug">
.intro(v-show="$store.state.intro" ref="intro")
  .intro__select(:class="{'is-non-active': $store.state.movie}")
    .select__texture
    .select__wrap
      .select__switch
        button.select__btn(data-btn="off" @click="Start($event.currentTarget.dataset.btn)")
          img(src="/assets/img/sound_off.svg", alt="OFF")
        button.select__btn(data-btn="on" @click="Start($event.currentTarget.dataset.btn)")
          img(src="/assets/img/sound_on.svg", alt="ON")
      p.select__text
        | 音声をONにしてお楽しみください
  video.intro__video.video--pc(ref="videoPc" @ended="End()" src="/assets/movie.mp4" playsinline)
  video.intro__video.video--sp(ref="videoSp" @ended="End()" src="/assets/movie_sp.mp4" playsinline)
</template>

<script>
import FADE from '@/module/fade'
import UA from '@/module/userAgent'

export default {
  data(){
    return{
      sound: null
    }
  },
  emits: ['postEl'],
  mounted(){
    this.$emit('postEl', this.$refs.intro)
    this.Landing()
  },
  methods:{
    Start(btn){
      this.$store.state.movie = true
      if(btn == 'off'){
        this.$store.state.sound = false
        this.$refs.videoPc.muted = true
        this.$refs.videoSp.muted = true
      }else {
        this.$store.state.sound = true
      }
      if(this.$route.path == '' || this.$route.path == '/'){
        switch (UA.IS_WIN) {
          case 0:
            this.$refs.videoPc.play()
            break;
          case 1:
            this.$refs.videoSp.play()
            break;
        }
      }else{
        this.End()
      }
    },
    End(){
      this.$store.state.contentsActive = true

      FADE(this.$refs.intro, 'out', 1000)

      if(this.$store.state.sound){
        this.$store.state.audio = new Audio('/assets/sound.mp3')
        this.$store.state.audio.loop = true
        if(this.$store.state.landing) this.$store.state.audio.play()
      }
    },
    Landing(){
      // ONの状態で別タブ開いた開いた時に、音を一旦OFFにしてタブに戻ってきたら音も復帰
      window.onblur = () => {
        this.$store.state.landing = false
        if(this.$store.state.sound){
          this.$refs.videoPc.muted = true
          this.$refs.videoSp.muted = true
          if(this.$store.state.audio) this.$store.state.audio.pause()
        }
      }
      window.onfocus = () => {
        this.$store.state.landing = true
        if(this.$store.state.sound){
          this.$refs.videoPc.muted = false
          this.$refs.videoSp.muted = false
          if(this.$store.state.audio) this.$store.state.audio.play()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.intro{
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.intro__select{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #141414;
  z-index: 1;
  @include transition();
}
.intro__select.is-non-active{
  opacity: 0;
  pointer-events: none;
}
.select__wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.select__texture{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/assets/img/intro_select_texture.png);
  background-repeat: repeat;
  pointer-events: none;
  mix-blend-mode: screen;
  opacity: 0.4;
}
.select__switch{

}
.select__btn{
  &:first-child{
    margin-right: 40px;
    @include mq('sp'){
      margin-right: 24px;
    }
  }
  img{
    @include mq('sp'){
      width: 86px;
      height: 86px;
    }
  }
}
.select__text{
  @include font('NotoSansJP-Light');
  margin-top: 28px;
  font-size: 1.4rem;
  letter-spacing: 0.07em;
  color: #F6F4EF;
  @include mq('sp'){
    margin-top: 24px;
    font-size: 1.3rem;
  }
}
.intro__video{
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
.video--pc{
  @include mq('pc'){
    display: block;
  }
  @include mq('sp'){
    display: none;
  }
}
.video--sp{
  @include mq('pc'){
    display: none;
  }
  @include mq('sp'){
    display: block;
  }
}
</style>