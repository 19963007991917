<template lang="pug">
div.form__complete
  div.complete__div1
    div
      p.en WE RECEIVED YOUR MESSAGE.&nbsp;
        br.sp
        | THANK YOU!
      p.ja メッセージを受け取りました。ありがとうございます！
  div.complete__div2
    FormShare(:duration="0.3" :ease="'power.in'" :repeat-delay="0.6")
  div.complete__div3
    FormBackButton
</template>

<script setup>
import FormShare from "./FormShare.vue";
import FormBackButton from "./FormBackButton.vue";
</script>

<style lang="scss" scoped>
.form__complete {
  text-align: center;
}

.complete__div1 {
  display: flex;
  justify-content: center;

  .en {
    @include font("bebas-neue-pro-Bold");
    letter-spacing: 0.05em;
    font-size: 26px;
    line-height: 31px;

    @include mq("sp") {
      font-size: 24px;
      line-height: 29px;
    }
  }

  .ja {
    @include font("NotoSansJP-Bold");
    font-size: 11px;
    line-height: 22px;
    margin-top: 4px;
    letter-spacing: 0;
    font-feature-settings: normal;

    @include mq("sp") {
      line-height: 20px;
      letter-spacing: -0.02em;
    }
  }
}

.complete__div2 {
  margin-top: 76px;
  @include mq("sp") {
    margin-top: 30px;
  }
}

.complete__div3 {
  width: 100%;
  text-align: center;
  margin-top: 42px;
  @include mq("sp") {
    margin-top: 30px;
  }
}
</style>
