<template lang="pug">
.itv__contents(@wheel="Wheel" ref="itvContents")
  .itv__inner(ref="itvInner")
    .screen
      h2.itv__heading(ref="itvHeading")
        img(src="/assets/img/itv_heading.png", alt="from court side")
      .slider(ref="itvSlider")
        .slider__index(ref="itvSliderIndex")
        .slider__frame
          .slider__carousel(v-if="$store.state.sliderActive" ref="itvSliderCarousel")
            .slider__cell(:data-index="index" v-for="key, index in $store.state.interviewData", ref="itvSliderCells")
              img.slider__image(:src="key.image.main")
      .text(ref="itvText")
        p.text__data
          span.data__id(ref="dataId")
          time.data__time(ref="dataTime")
        h1.text__heading.heading
          span.heading__main(ref="headingMain")
          span.heading__sub(ref="headingSub")
        ul.text__tagList.tagList
          li.tagList__item(:data-tag="tag" v-for="tag in tagList" @click="Sort")
            span #
            | {{ tag }}
        .readMore(ref="readMore" @click="Accordion" data-articleId="")
          span.readMore__btn.close(v-if="$store.state.accordion") CLOSE
          span.readMore__btn.open(v-else) READ MORE
  .entry(ref="itvEntry")
    Article(v-if="$store.state.accordion" :toInterviewData="articleData")
    .controller(ref="itvController")
      .controller__wrapper
        .scroll(v-show="$store.state.scroll")
        .pager(ref="pager")
          .pager__button.prev(data-button="prev" @click="PagerButton")
          .pager__button.next(data-button="next" @click="PagerButton")
          .pager__wrapper
            .pager__inner
              .pager__truck(ref="pagerTruck")
                .pager__index(v-for="key, index in $store.state.interviewData" :data-index="index + 1" ref="pagerIndex")
        .voice(v-show="$store.state.scroll")
          h3.voice__heading
            | VOICES
          ul.voice__list
            li.list__item
              a.list__link(href="https://t.co/yqhJJJ0o6g", target="_blank")
                img(src="/assets/img/controller_voice_podcast.svg", alt="podcast")
            li.list__item
              a.list__link(href="https://t.co/4wl9eLyNke", target="_blank")
                img(src="/assets/img/controller_voice_youtube.svg", alt="youtube")
            li.list__item
              a.list__link(href="https://t.co/hRY3ucs6zA", target="_blank")
                img(src="/assets/img/controller_voice_spotify.svg", alt="spotify")
      .controller__back(ref="controllerBack" @click="Accordion" v-show="$store.state.accordion" data-articleId="")
        span.back__text BACK
</template>

<script>
import axios from 'axios'
import gsap from 'gsap'
import UA from '@/module/userAgent'

import Article from '@/components/article'

export default {
  components:{
    Article,
  },
  data(){
    return{
      build: false,
      articleData: null,
      articleIndex: 0,
      sliderLastIndex: 0,
      duration: 300,
      easing: 'ease-out',
      sliderFlag: false,
      sliderRange: 0,
      sliderLastTime: 0,
      sliderLockTime: 1300,
      sliderTouchBefore: 0,
      sliderTouchAfter: 0,
      sliderTouchRange: 100,
      pagerFont: [22, 22],
      pagerMargin: [18, 14],
      pagerDummy: 6,
      pagerDummyFlag: false,
      tagList: []
    }
  },
  created(){
    if(this.$store.state.accordion) this.articleData = this.$store.state.interviewData[this.$store.state.current - 1]
  },
  mounted(){
    axios
      .get('/assets/json/interviews.json?timestamp='+new Date().getTime())
      .then((response) => {
        this.$store.state.interviewData = response.data.interviews

        for (let index = 0; index < this.$store.state.interviewData.length; index++) {
          this.$store.state.interviewData[index].index = index
        }

        this.$nextTick(() => {
          this.SliderIndex()
          this.SliderText()
          this.tagList = this.$store.state.interviewData[this.$store.state.current - 1].tag
          if(this.$store.state.itvBuild) {
            this.SliderInit()
            this.SlideActive()
            this.PagerInit()
            this.Anime()
            this.Accordion()
          }
        })
      })
  },
  watch:{
    ArticleOpen(){
      this.Accordion()
    },
    Start(){
      this.Anime()
    },
    Id(){
      this.IdInit()
    },
    Slider(){
      this.$nextTick(() => {
        this.SliderInit()
        this.SlideActive()
      })
    },
    Pager(){
      this.$nextTick(() => {
        this.PagerInit()
      })
    }
  },
  computed:{
    ArticleOpen(){
      return this.$store.state.itvArticleOpen
    },
    Start(){
      return this.$store.state.contentsActive
    },
    Id(){
      return this.$store.state.current
    },
    Slider(){
      return this.$store.state.sliderActive
    },
    Pager(){
      return this.$store.state.sliderActive
    },
  },
  methods:{
    Sort(e){
      this.$store.state.itvPage = false
      this.$store.state.itvTagCategory = e.target.dataset.tag
    },
    Anime(){
      const gsapTL = gsap.timeline()
      const t = {
        _a: this.$store.state.itvBuild ? 0 : 0.4,
        _b: this.$store.state.itvBuild ? 0 : 1,
        _c: this.$store.state.itvBuild ? 0 : 2,
        _d: this.$store.state.itvBuild ? 0 : 1,
        _e: this.$store.state.itvBuild ? 0 : 1,
        _f: this.$store.state.itvBuild ? 0 : 1,
        _g: this.$store.state.itvBuild ? 0 : -0.6,
        _h: this.$store.state.itvBuild ? 0 : 1,
        _i: this.$store.state.itvBuild ? 0 : -0.6,
      }
      gsapTL
        .to(this.el_itvBg, {
          duration: t._a,
          delay: t._b,
          ease: 'sine.out',
          opacity: 1,
        })
        .to(this.$refs.itvHeading, {
          duration: t._c,
          delay: t._d,
          ease: 'sine.out',
          opacity: 1,
        })
        .to(this.$refs.itvSlider, {
          duration: t._e,
          ease: 'sine.out',
          opacity: 1,
        })
        .to(this.$refs.itvText, {
          duration: t._f,
          delay: t._g,
          ease: 'sine.out',
          opacity: 1,
        })
        .to(this.$refs.itvEntry, {
          duration: t._h,
          delay: t._i,
          ease: 'sine.out',
          opacity: 1,
        })
      const total = (t._a + t._b + t._c + t._d + t._e + t._f + t._g + t._h + t._i) * 1000
      setTimeout(() =>{
        this.$store.state.itvBuild = true
      }, total)
    },
    IdInit(){
      if(this.$store.state.current !== 0 && this.$store.state.current !== this.sliderLastIndex){
        const index = this.$store.state.current - 1
        this.$refs.readMore.dataset.articleId = this.$store.state.interviewData[index].id
        this.$refs.controllerBack.dataset.articleId = this.$store.state.interviewData[index].id
        this.el_itvBg = document.querySelector('.itv__bg')
        this.el_itvBg.style.backgroundImage = 'url('+this.$store.state.interviewData[index].image.bg+')'
        this.tagList = this.$store.state.interviewData[this.$store.state.current - 1].tag
      }
    },
    SliderInit(){
      if(!this.$store.state.sliderActive && !this.$store.state.itvPage) return

      // スライダーの長さ取得
      this.sliderLastIndex = this.$store.state.interviewData.length + 1
      // 最初と最後の要素のクローンを生成
      const fistClone = this.$refs.itvSliderCarousel.firstElementChild.cloneNode(true)
      const lastClone = this.$refs.itvSliderCarousel.lastElementChild.cloneNode(true)
      this.$refs.itvSliderCarousel.append(fistClone)
      this.$refs.itvSliderCarousel.prepend(lastClone)
      // 初期位置設定
      this.SliderSizeSet()
      window.addEventListener('resize', this.SliderSizeSet.bind())
    },
    SliderSizeSet(){
      if(!this.$store.state.itvPage) return
      const itvSlider = document.querySelector('.slider')
      const itvSliderCarousel = document.querySelector('.slider__carousel')
      this.sliderRange = itvSlider.getBoundingClientRect().height * this.$store.state.current
      itvSliderCarousel.style.transform = "translate3d(0, -"+this.sliderRange+"px, 0)"
    },
    SliderIndex(){
      this.$refs.itvSliderIndex.textContent = '#'+this.$store.state.interviewData[this.$store.state.current - 1].id
    },
    SliderText(){
      this.$refs.dataId.textContent = 'INTERVIEW #'+this.$store.state.interviewData[this.$store.state.current-1].id
      this.$refs.dataTime.textContent = this.$store.state.interviewData[this.$store.state.current-1].date
      this.$refs.headingMain.textContent = this.$store.state.interviewData[this.$store.state.current-1].title
      this.$refs.headingSub.textContent = this.$store.state.interviewData[this.$store.state.current-1].interviee
    },
    SliderTouchstart(e){
      this.sliderTouchBefore = e.changedTouches[0].pageY
    },
    SliderTouchmove(e){
      this.sliderTouchAfter = e.changedTouches[0].pageY
    },
    SliderTouchend(){
      if(!this.$store.state.sliderActive || this.$store.state.accordion) return

      if(this.sliderTouchRange < this.sliderTouchBefore - this.sliderTouchAfter){
        this.$store.state.current++
      }else if(-this.sliderTouchRange > this.sliderTouchBefore - this.sliderTouchAfter){
        this.$store.state.current--
      }
      this.SlideActive()
      this.FocusPagination(this.$store.state.current)
    },
    SlideActive(dummyIndex){
      // スライダー
      this.sliderRange = this.$refs.itvSlider.getBoundingClientRect().height * this.$store.state.current
      this.$refs.itvSliderCarousel.style.transitionDuration = this.duration+'ms'
      this.$refs.itvSliderCarousel.style.transitionTimingFunction = this.easing
      this.$refs.itvSliderCarousel.style.transform = 'translate3d(0, -'+this.sliderRange+'px, 0)'
      // ページャー
      this.$refs.pagerTruck.style.transitionDuration = this.duration+'ms'
      this.$refs.pagerTruck.style.transitionTimingFunction = this.easing
      if(this.pagerDummyFlag){
        this.$refs.pagerTruck.style.transform = 'translate3d(calc((-'+this.pagerFont[UA.IS_WIN]+'px * '+dummyIndex+') + ((-'+this.pagerMargin[UA.IS_WIN]+'px * '+dummyIndex+') + '+this.pagerMargin[UA.IS_WIN]+'px)), 0, 0)'
      }else{
        this.$refs.pagerTruck.style.transform = 'translate3d(calc((-'+this.pagerFont[UA.IS_WIN]+'px * '+this.$store.state.current+') + ((-'+this.pagerMargin[UA.IS_WIN]+'px * '+this.$store.state.current+') + '+this.pagerMargin[UA.IS_WIN]+'px)), 0, 0)'
      }

      this.$refs.itvSliderCarousel.addEventListener('transitionend', () => {
        // スライダー
        this.$refs.itvSliderCarousel.style.transitionDuration = ''
        this.$refs.itvSliderCarousel.style.transitionTimingFunction = ''
        // ページャー
        this.$refs.pagerTruck.style.transitionDuration = ''
				this.$refs.pagerTruck.style.transitionTimingFunction = ''

        if(this.$store.state.current == this.sliderLastIndex){
          // スライダー
          this.$refs.itvSliderCarousel.style.transform = 'translate3d(0, -'+this.$refs.itvSlider.getBoundingClientRect().height+'px, 0)'
          // ページャー
          this.$refs.pagerTruck.style.transform = 'translate3d(calc((-'+this.pagerFont[UA.IS_WIN]+'px) + ((-'+this.pagerMargin[UA.IS_WIN]+'px) + '+this.pagerMargin[UA.IS_WIN]+'px)), 0, 0)'

          this.$store.state.current = 1
        }else if(this.$store.state.current == 0){
          // スライダー
          this.$refs.itvSliderCarousel.style.transform = 'translate3d(0, -'+(this.$refs.itvSlider.getBoundingClientRect().height * (this.sliderLastIndex - 1))+'px, 0)'
          // ページャー
          this.$refs.pagerTruck.style.transform = 'translate3d(calc((-'+this.pagerFont[UA.IS_WIN]+'px * '+(this.sliderLastIndex - 1)+') + ((-'+this.pagerMargin[UA.IS_WIN]+'px * '+(this.sliderLastIndex - 1)+') + '+this.pagerMargin[UA.IS_WIN]+'px)), 0, 0)'

          this.$store.state.current = this.sliderLastIndex - 1
        }else if(this.pagerDummyFlag){
          this.$refs.pagerTruck.style.transform = 'translate3d(calc((-'+this.pagerFont[UA.IS_WIN]+'px * '+this.$store.state.current+') + ((-'+this.pagerMargin[UA.IS_WIN]+'px * '+this.$store.state.current+') + '+this.pagerMargin[UA.IS_WIN]+'px)), 0, 0)'
        }

        this.SliderIndex()
        this.SliderText()
      })
    },
    Wheel(e){
      // アコーディオンが開いているときは発火させない
      if(!this.$store.state.itvBuild || !this.$store.state.sliderActive || this.$store.state.accordion) return

      // イベントが発火してからthis.sliderLockTime秒以上経つまで発火させない
      const timeNow = new Date().getTime()
      if(timeNow - this.sliderLastTime < this.sliderLockTime) return

      if(e.deltaY > 0 && this.$store.state.current < this.sliderLastIndex){
        this.$store.state.current++
      }else if(e.deltaY < 0 && this.$store.state.current > 0){
        this.$store.state.current--
      }

      if(this.pagerDummyFlag) this.pagerDummyFlag = false

      this.SlideActive()
      this.FocusPagination()

      this.sliderLastTime = timeNow
    },
    FocusPagination(){
      this.$refs.itvSliderCarousel.addEventListener('transitionend', () => {
        const activePager = document.querySelector('.pager__index.is-active')
        activePager.classList.remove('is-active')
        this.$refs.pagerIndex[this.$store.state.current-1].classList.add('is-active')
      })
    },
    PagerInit(){
      for (let index = 0; index < this.$store.state.interviewData.length; index++) {
        this.$refs.pagerIndex[index].textContent = '#'+this.$store.state.interviewData[index].id
      }
      // 前半のインデックスにダミーに追加
      for (let index = 0; index < this.pagerDummy; index++) {
        const clone = this.$refs.pagerIndex[index].cloneNode(true);
        clone.classList.add('dummy')
        clone.dataset.dummyIndex = Number(clone.dataset.index) + this.$store.state.interviewData.length
        this.$refs.pagerTruck.append(clone)
      }
      // 後半のインデックスにダミーに追加
      for (let index = this.$store.state.interviewData.length - 1; index >= this.$store.state.interviewData.length - this.pagerDummy; index--) {
        const clone = this.$refs.pagerIndex[index].cloneNode(true)
        clone.classList.add('dummy')
        clone.dataset.dummyIndex = Number(clone.dataset.index) - this.$store.state.interviewData.length
        this.$refs.pagerTruck.prepend(clone)
      }

      this.$refs.pagerIndex[this.$store.state.current - 1].classList.add('is-active')

      const pagerIndexArray = document.querySelectorAll('.pager__index')
      pagerIndexArray.forEach(element => {
        element.addEventListener('click', this.PagerActive)
      })
    },
    PagerButton(e){
      if(e.target.dataset.button == 'next' && this.$store.state.current < this.sliderLastIndex){
        this.$store.state.current++
      }else if(e.target.dataset.button == 'prev' && this.$store.state.current > 0){
        this.$store.state.current--
      }

      if(this.pagerDummyFlag) this.pagerDummyFlag = false

      this.SlideActive()
      this.FocusPagination(this.$store.state.current)
      this.ArticleChange()
    },
    PagerActive(e){
      this.$store.state.current = Number(e.target.dataset.index)
      this.pagerDummyFlag = e.target.classList.contains('dummy')

      this.SlideActive(e.target.dataset.dummyIndex)
      this.FocusPagination()
      this.ArticleChange()
    },
    ArticleChange(){
      // 記事の変更
      this.articleData = this.$store.state.interviewData[this.$store.state.current - 1]

      if(this.$store.state.accordion){
        history.replaceState('','',this.$store.state.interviewData[this.$store.state.current - 1].link)
        this.$route.path = this.$store.state.interviewData[this.$store.state.current - 1].link

        this.$refs.itvContents.scrollTop = 0
      }
    },
    Accordion(){
      if(!this.$store.state.accordion){
        this.$store.state.accordion = true
        this.$store.state.scroll = false

        this.articleData = this.$store.state.interviewData[this.$store.state.current - 1]

        history.replaceState('','',this.$store.state.interviewData[this.$store.state.current - 1].link)
        this.$route.path = this.$store.state.interviewData[this.$store.state.current - 1].link
        this.$store.state.page = 'interview'
      }else{
        this.$store.state.accordion = false
        this.$store.state.scroll = true

        this.articleData = null

        history.replaceState('','','/interview')
        this.$route.path = '/interview'
        this.$store.state.page = 'interview'
      }
    },
  }
}
</script>

<style lang="scss">
.itv__contents{
  overscroll-behavior: none;
  overflow-y: auto;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.itv__inner{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  @include mq("sp"){
    height: calc(100vh - $closeH);
  }
}
.screen{
  position: relative;
  left: -4.4vw;
  bottom: 40px;
  margin: 0 auto;
}
.itv__heading{
  position: absolute;
  top: -8.59375vw;
  left: 0;
  width: 62.421875vw;
  opacity: 0;
  @include mq("sp"){
    top: -54px;
    left: -3.733333333333334vw;
    margin: 0 auto;
    width: 86.4vw;
    max-width: 324px;
    height: auto;
  }
  img{
    width: 100%;
    height: auto;
  }
}

.slider{
  position: relative;
  left: 4.4vw;
  margin: 0 auto;
  width: 54.6875vw;
  height: 28.59375vw;
  z-index: 1;
  opacity: 0;
  @include mq("sp"){
    left: 3.733333333333334vw;
    width: 73.866666666666667vw;
    max-width: 277px;
    height: 63.2vw;
    max-height: 237px;
  }

  .slider__index{
    @include font("bebas-neue-pro-Light_italic");
    position: absolute;
    top: 0;
    left: calc(100% - 2.65625vw);
    bottom: 0;
    margin: auto 0;
    padding-right: 4px;
    height: 1em;
    font-size: 6.5625vw;
    color: #FF5252;
    z-index: 1;
    @include mq("sp"){
      left: calc(100% - 42px);
      font-size: 6.1rem;
    }
  }
  .slider__frame{
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .slider__carousel{

  }
  .slider__cell{
    img{
      width: 54.6875vw;
      height: 28.59375vw;
      @include mq("sp"){
        width: 73.866666666666667vw;
        max-width: 277px;
        height: 63.2vw;
        max-height: 237px;
      }
    }
  }
  .slider__image{
    width: 100%;
    @include mq("sp"){
      width: 73.8666666667vw;
      max-width: 277px;
      height: 63.2vw;
      max-height: 237px;
      object-fit: cover;
    }
  }
}

.text{
  position: absolute;
  bottom: -60px;
  padding: 30px 26px;
  width: 36.328125vw;
  background-color: rgba($color: #F1EFED, $alpha: 0.9);
  z-index: 2;
  opacity: 0;
  @include mq("sp"){
    bottom: -80px;
    left: -3.733333333333334vw;
    padding: 20px;
    width: 284px;
  }

  .text__data{
    @include font("bebas-neue-pro_italic");
    margin-bottom: 8px;
    color: #83827F;
    font-weight: 600;
    font-size: 1.015625vw;
    letter-spacing: 0.02em;
    @include mq("sp"){
      font-size: 1.5rem;
    }
    .data__id{
      display: inline-block;
    }
    .data__time{
      display: inline-block;
      margin-left: 8px;
    }
  }
  .text__heading{
    margin-bottom: 12px;
    line-height: 1.6;
    color: #222;
    .heading__main{
      @include font("NotoSansJP-Bold");
      display: block;
      font-size: 1.328125vw;
      @include mq("sp"){
        font-size: 1.6rem;
      }
    }
    .heading__sub{
      @include font("NotoSansJP-Medium");
      display: block;
      font-size: 1.09375vw;
      @include mq("sp"){
        font-size: 1.3rem;
      }
    }
  }

  .text__tagList.tagList{
    @include font("NotoSansJP-Bold");
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 14px;
    font-size: 1.1rem;
    color: #858585;
    line-height: 1.5;
    .tagList__item{
      margin-right: 8px;
      cursor: pointer;
    }
    .tagList__item:last-child{
      margin-right: 0;
    }
  }

  .readMore{
    @include font("bebas-neue-pro_italic");
    @include hoverLine();
    display: inline-block;
    position: relative;
    line-height: 1.5;
    font-size: 1.09375vw;
    letter-spacing: 0.02em;
    color: #222222;
    cursor: pointer;
    @include mq("sp"){
      font-size: 1.3rem;
    }
    .readMore__btn{
    }
    .readMore__btn.close{
      position: relative;
      &:before{
        content: "";
        display: inline-block;
        position: relative;
        top: 2px;
        margin-right: 6px;
        width: 12px;
        height: 12px;
        background-image: url(/assets/img/readMore_close_arrow.svg);
        background-repeat: no-repeat;
      }
    }
    .readMore__btn.open,.readMore__btn.close{
      
    }
  }
}

.entry{
  position: relative;
  margin-top: -130px;
  padding-bottom: 130px;
  color: #DBD6D0;
  opacity: 0;
}
.controller{
  position: absolute;
  right: 0;
  bottom: 60px;
  left: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 900px;
  text-align: center;
}
.controller__wrapper{
  display: flex;
  justify-content: center;
  width: 100%;
}
.controller__back{
  @include font("bebas-neue-pro_italic");
  @include hoverLine();
  display: inline-block;
  position: relative;
  margin: 30px auto 0;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.5;
}
.back__text{
  position: relative;
  cursor: pointer;
  &::before{
    content: "";
    display: inline-block;
    margin-right: 8px;
    width: 12px;
    height: 11px;
    background-image: url(/assets/img/controller_arrow.svg);
  }
}

.scroll{
  position: absolute;
  left: 0;
  @include mq("sp"){
    display: none;
  }
  &::before,&::after{
    content: "";
    display: inline-block;
    width: 33px;
    height: 33px;
    background-image: url(/assets/img/scroll_arrow.svg);
  }
  &::after{
    margin-left: 6px;
    transform: rotate(180deg);
  }
}
.pager{
  position: relative;
  margin: 0 auto;
  width: calc((22px * 7) + (18px * 6));
  @include mq("sp"){
    width: calc((22px * 7) + (14px * 6));
  }
  .pager__wrapper{
    overflow: hidden;
    width: 100%;
  }
  .pager__inner{
    // transform: translateX(calc((22px * 4) + (18px * 3)));
    // transform: translateX(calc((22px * 1) + (18px * 0)));
    // transform: translateX(calc((22px * 0) + (18px * -1)));
    transform: translateX(calc((22px * -2) + (18px * -3)));
    @include mq("sp"){
      transform: translateX(calc((22px * -2) + (14px * -3)));
    }
  }
  .pager__truck{
    white-space: nowrap;
  }
  .pager__button{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: calc(14px * 2);
    height: calc(6px * 2);
    &:before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 14px;
      height: 6px;
      background-repeat: no-repeat;
    }
  }
  .pager__button.prev{
    left: calc(-14px + -28px);
    cursor: pointer;
    @include mq("sp"){
      left: calc(-14px + -16px);
    }
    &:before{
      background-image: url(/assets/img/pager_arrow_prev_active.svg);
    }
  }
  .pager__button.next{
    right: calc(-14px + -28px);
    cursor: pointer;
    @include mq("sp"){
      right: calc(-14px + -16px);
    }
    &:before{
      background-image: url(/assets/img/pager_arrow_next_active.svg);
    }
  }
  .pager__index{
    @include font("bebas-neue-pro-Middle_italic");
    display: inline-block;
    margin-right: 18px;
    width: 22px;
    line-height: 1.5;
    font-size: 1.8rem;
    color: #DBD6D0;
    text-align: center;
    letter-spacing: 0.02em;
    opacity: 0.3;
    cursor: pointer;
    @include mq("sp"){
      margin-right: 14px;
    }
    &:last-child{
      margin-right: 0;
    }
  }
  .pager__index.is-active{
    position: relative;
    opacity: 1;
    @include hoverLine();
  }
}

.voice{
  position: absolute;
  right: 0;
  bottom: -12px;
  @include mq("sp"){
    display: none;
  }
  .voice__heading{
    @include font("neue-haas-grotesk-display");
    margin-bottom: 12px;
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 0.02em;
  }
  .voice__list{
    display: flex;
    justify-content: space-between;
  }
  .list__item{
    margin-right: 28px;
    &:last-child{
      margin-right: 0;
    }
  }
  .list__link{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>