<template lang="pug">
article.article(:data-id="toInterviewData.id")
  .article__wrap
    .article__outline {{ toInterviewData.description }}
    section.article__section(v-for="key, index in toInterviewData.contents")
      h2.article__heading {{ key.head }}
      .article__block(v-for="key, index in key.conversations")
        p.article__host(v-if="key.speaker == '――'")
          | ―― {{ key.text }}
        p.article__speaker(v-else)
          span.speaker__name {{ key.speaker }}
          | {{ key.text }}
        img.article__image(v-if="key.photo !== ''" :src="key.photo")
    p.article__note(v-if="toInterviewData.notes" v-html="toInterviewData.notes")
    .article__profile
      .profile__wrap
        h2.profile__heading
          | Profile
      ul.profile__list
        li.list__item(v-for="key, index in toInterviewData.profiles")
          .list__block
            img.list__image(:src="key.photo", :alt="key.name")
          .list__block
            .list__name {{ key.name }}（{{ key.kana }}）
            p.list__text
              | {{ key.description }}
    .article__voice
      h3.voice__heading VOICES FROM COURT SIDE
      p.voice__read インタビューの様子を音声でもお楽しみいただけます
      ul.voice__list
        li.list__item
          a.list__link(:href="toInterviewData.voices.podcast", :class="{'is-non-active': toInterviewData.voices.podcast  == '' }", target="_blank")
            picture
              source(media="(max-width: 750px)", srcset="/assets/img/article_voice_podcast_sp.svg")
              img(src="/assets/img/article_voice_podcast.svg", alt="podcast")
        li.list__item
          a.list__link(:href="toInterviewData.voices.youtube", :class="{'is-non-active': toInterviewData.voices.youtube == ''}", target="_blank")
            picture
              source(media="(max-width: 750px)", srcset="/assets/img/article_voice_youtube_sp.svg")
              img(src="/assets/img/article_voice_youtube.svg", alt="youtube")
        li.list__item
          a.list__link(:href="toInterviewData.voices.spotify", :class="{'is-non-active': toInterviewData.voices.spotify == ''}", target="_blank")
            picture
              source(media="(max-width: 750px)", srcset="/assets/img/article_voice_spotify_sp.svg")
              img(src="/assets/img/article_voice_spotify.svg", alt="spotify")
    .article__sns
      p.sns__share SHARE
      ul.sns__list
        li.list__item
          a.list__link.sns(ref="sns" data-id="line" :data-text="toInterviewData.title" :href="'https://www.slamdunk-movie-courtside.jp' + toInterviewData.link", target="_blank")
            img(src="/assets/img/article_sns_line.svg", alt="line")
        li.list__item
          a.list__link.sns(ref="sns" data-id="facebook" :data-text="toInterviewData.title" :href="'https://www.slamdunk-movie-courtside.jp' + toInterviewData.link", target="_blank")
            img(src="/assets/img/article_sns_facebook.svg", alt="facebook")
        li.list__item
          a.list__link.sns(ref="sns" data-id="twitter" :data-text="toInterviewData.title" :href="'https://www.slamdunk-movie-courtside.jp' + toInterviewData.link", target="_blank")
            img(src="/assets/img/article_sns_twitter.svg", alt="twitter")
</template>

<script>
import SNS_Share from '@/module/snsShare'

export default {
  props: [
    'toInterviewData'
  ],
  watch:{
    getInterviewData(){
      this.$nextTick(() => {
        this.Share()
      })
    }
  },
  computed:{
    getInterviewData(){
      return this.toInterviewData
    }
  },
  mounted(){
    this.Share()
  },
  methods:{
    Share(){
      const array = document.querySelectorAll('.article .sns')
      SNS_Share(array)
    }
  }
}
</script>

<style lang="scss" scoped>

.article{
  overflow: hidden;
}
.article__wrap{
  margin: 0 auto;
  padding: 90px 0 30px;
  max-width: 660px;
  @include mq('sp'){
    padding: 90px 24px 30px;
  }
}
.article__outline{
  @include font('NotoSansJP-Regular');
  margin-bottom: 80px;
  padding: 48px 52px;
  background-color: #F1EFED;
  font-size: 1.5rem;
  color: #222222;
  line-height: 1.8;
  letter-spacing: 0.02em;
  @include mq('sp'){
    margin-bottom: 60px;
    padding: 30px;
    font-size: 1.4rem;
  }
}
$articleIndent: 40px;
.article__heading{
  @include font('NotoSansJP-Bold');
  position: relative;
  padding: 0 $articleIndent;
  margin-top: 50px;
  margin-bottom: 52px;
  letter-spacing: 0.02em;
  font-size: 2.8rem;
  line-height: 1.5;
  @include mq('sp'){
    margin-bottom: 40px;
    padding-bottom: 12px;
    padding-left: 0;
    font-size: 2.2rem;
  }
  &::before{
    content: "";
    position: absolute;
    left: 20px;
    width: 2px;
    height: 100%;
    background-color: #FF5252;
    @include mq('sp'){
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }
  }
}
.article__host{
  @include font('NotoSansJP-Bold');
  margin-bottom: 46px;
  padding: 0 $articleIndent;
  line-height: 2;
  @include mq('sp'){
    margin-bottom: 30px;
    padding-left: 0;
    font-size: 1.4rem;
  }
}
.article__speaker{
  @include font('NotoSansJP-Light');
  margin-bottom: 46px;
  padding: 0 $articleIndent;
  font-size: 1.6rem;
  line-height: 2;
  @include mq('sp'){
    margin-bottom: 30px;
    padding: 0;
    font-size: 1.4rem;
  }
  .speaker__name{
    @include font('NotoSansJP-Bold');
    margin-right: 1em;
  }
}
.article__image{
  margin-bottom: 46px;
  width: 100%;
  @include mq('sp'){
    margin-bottom: 30px;
  }
}
.article__note{
  padding-left: $articleIndent;
  line-height: 2;
  font-size: 1.1rem;
  @include mq('sp'){
    padding-left: 0;
  }
}
.article__profile{
  margin-top: 80px;
  padding: 70px 60px;
  background-color: #F1F0E8;
  @include mq('sp'){
    margin-top: 70px;
    padding: 30px;
  }
  .profile__wrap{

  }
  .profile__heading{
    @include font('NotoSansJP-Medium');
    position: relative;
    margin-bottom: 34px;
    padding-left: 20px;
    font-size: 3rem;
    color: #222222;
    @include mq('sp'){
      margin-bottom: 0;
    }
    &::before{
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      width: 4px;
      height: 100%;
      background-color: #FF5252;
    }
  }
  .profile__list{

  }
  .list__item{
    display: flex;
    justify-content: center;
    padding: 36px 0;
    border-bottom: solid 1px #BFBFBF;
    @include mq('sp'){
      display: block;
    }
    &:last-child{
      padding-bottom: 0;
      border-bottom: 0px;
    }
  }
  .list__block{
    &:first-of-type{
      margin-right: 46px;
      @include mq('sp'){
        margin-right: 0;
        margin-bottom: 28px;
      }
    }
  }
  .list__image{
    display: block;
    margin: 0 auto;
    width: 132px;
    height: 132px;
    @include mq('sp'){
      width: 168px;
      height: 168px;
    }
  }
  .list__name{
    @include font('NotoSansJP-Bold');
    margin-bottom: 12px;
    color: #222222;
    font-size: 1.8rem;
    @include mq('sp'){
      font-size: 1.6rem;
    }
  }
  .list__text{
    @include font('NotoSansJP-Regular');
    line-height: 1.8;
    color: #222222;
    font-size: 1.3rem;
    @include mq('sp'){
      font-size: 1.2rem;
    }
  }
}
.article__voice{
  margin-top: 54px;
  @include mq('sp'){
    margin-top: 36px;
  }
  .voice__heading{
    @include font('neue-haas-grotesk-display');
    margin-bottom: 8px;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    @include mq('sp'){
      font-size: 1.3rem;
    }
  }
  .voice__read{
    @include font('NotoSansJP-Regular');
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.3rem;
    @include mq('sp'){
      font-size: 1.2rem;
    }
  }
  .voice__list{
    display: flex;
    justify-content: space-between;
  }
  .list__item{
    margin-right: 8px;
    width: 200px;
    height: 54px;
    border: solid 1px #7A7A7A;
    @include mq('sp'){
      width: 33.3333333333%;
    }
    &:last-child{
      margin-right: 0;
    }
  }
  .list__link{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .list__link.is-non-active{
    pointer-events: none;
  }
}
.article__sns{
  display: flex;
  flex-direction: column;
  margin: 36px auto 0;
  @include mq('sp'){
    margin: 24px auto 0;
  }

  .sns__share{
    @include font('neue-haas-grotesk-display');
    margin-bottom: 12px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    @include mq('sp'){
      font-size: 1rem;
    }
  }

  .sns__list{
    display: flex;
    margin: 0 auto;
  }
  .list__item{
    margin-right: 36px;
    &:last-child{
      margin-right: 0;
    }
  }
  .list__link{
    img{
      vertical-align: -webkit-baseline-middle;
    }
  }
}
</style>