import { createStore } from 'vuex'

export default createStore({
  state: {
    page: '',
    intro: true,
    audio: null,
    movie: false,
    sound: false,
    landing: false,
    court: true,
    contentsActive: false,
    sliderActive: false,
    menu: false,
    menuMessage: false,
    menuInterview: false,
    menuAbout: false,
    about: false,
    navColor: false,
    accordion: false,
    scroll: true,
    pagerButtonPrev: true,
    pagerButtonNext: true,
    msgStart: false,
    msgBuild: false,
    msgPage: true,
    msgActive: false,
    itvStart: false,
    itvArticleOpen: false,
    itvBuild: false,
    itvPage: true,
    itvTagCategoryHeading: '',
    interviewData: [],
    current: 0,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
