<template lang="pug">
div.msg__form
  div.form__div1(v-if="!isComplete")
    div.form__div2
      p.en Send a message and support the&nbsp;
        br.pc
        | characters and creators of THE FIRST&nbsp;
        br.pc
        | SLAM DUNK
        br
        span.form__note ※Please enter in Japanese or English
      p.ja 湘北の選手たちや、映画『THE FIRST SLAM&nbsp;
        br.pc
        | DUNK』に関わるすべての人たちへ応援メッ
        br.pc
        | セージを送ろう
        br
        span.form__note ※日本語または英語で入力してください
    div.form__div3
      FormInputs(:onSubmit="handleSubmit" :submitted="submitted")
      div.form__error(v-if="isError")
        p メッセージの送信に失敗しました。再度投稿ボタンを押してください。
        p Failed to send message. Please press the submit button again.
      div.form__div4
        FormBackButton
  div.form__div5(v-if="isComplete")
    FormComplete
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import FormInputs from "./FormInputs.vue";
import FormComplete from "./FormComplete.vue";
import FormBackButton from "./FormBackButton.vue";

const SUCCESS = 200;
const isError = ref(false);
const isComplete = ref(false);
const submitted = ref(false);

const handleSubmit = (values) => {
  submitted.value = true
  axios
    .post("/api/message/publish", values)
    .then((response) => {
      if(!response.data.result) {
        isError.value = !response.data.result;
        console.error(`${response.data.message}`)
        
        return
      }
      
      isComplete.value = response.status === SUCCESS;
    })
    .catch((error) => {
      isError.value = true;
      console.error(error)
    }).finally(() => {
      submitted.value = false
    });
};
</script>

<style lang="scss" scoped>
.msg__form {
  @include transition();
  color: #222;
  text-shadow: 1px 1px 1px rgba(247, 246, 237, 0.6), -1px 1px 1px rgba(247, 246, 237, 0.6), 1px -1px 1px rgba(247, 246, 237, 0.6),
    -1px -1px 1px rgba(247, 246, 237, 0.6), 1px 0px 1px rgba(247, 246, 237, 0.6), 0px 1px 1px rgba(247, 246, 237, 0.6),
    -1px 0px 1px rgba(247, 246, 237, 0.6), 0px -1px 1px rgba(247, 246, 237, 0.6);
}

.form__div1 {
  display: flex;
  width: 85.6617647059%;
  padding-top: 236px;
  padding-bottom: 60px;
  position: relative;
  left: 90px;

  @include mq("sp") {
    width: 100%;
    position: relative;
    left: 0;
    padding-top: 194px;
    padding-left: 24px;
    padding-right: 24px;
  }

  @include mq("sp") {
    display: block;
    width: 100%;
  }
}

.form__div2 {
  width: 280px;
  margin-right: 92px;
  flex-shrink: 0;
  @include mq("sp") {
    width: 100%;
    margin-right: 0;
  }

  .en {
    @include font("neue-haas-grotesk-display-Pro");
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.03em;

    @include mq("sp") {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.03em;
    }
  }

  .ja {
    @include font("NotoSansJP-Medium");
    font-size: 13px;
    margin-top: 16px;
    line-height: 20px;
    letter-spacing: 0;
    font-feature-settings: normal;

    @include mq("sp") {
      font-size: 12px;
      line-height: 170%;
      margin-top: 10px;
    }
  }
}

.form__div3 {
  width: calc(100% - 372px);
  min-width: 268px;
  @include mq("sp") {
    width: 100%;
    margin-top: 42px;
    min-width: 0px;
  }
}

.form__div4 {
  margin-top: 32px;
  @include mq("sp") {
    margin-top: 20px;
  }
}

.form__div5 {
  position: relative;
  top: 328px;
  @include mq("sp") {
    top: 243px;
  }
}

.form__note {
  font-size: 11px;
}

.form__error {
  @include font("NotoSansJP-Medium");
  text-shadow: none;
  padding: 21px 21px 26px;
  margin-top: 25px;
  width: 100%;
  background-color: #b70101;
  color: #fff;
  font-size: 11px;
  line-height: 100%;
  p {
    margin-top: 8px;
    &:first-of-type {
      margin-top: 0;
    }
  }
}
</style>
