<template lang="pug">
form.inputs(@submit="onSubmit")
  div.inputs__item
    label.inputs__label(for="name")
      span.en COURT NAME
      span.ja コートネーム
      span.ja.required ※{{ errors.name }}
    input(type="text" id="name" placeholder="Nickname  /  ニックネーム" v-model="name")
    p.inputs__count {{ nameCount }}/{{ MAX.name }}
  div.inputs__item
    label.inputs__label(for="position")
      span.en POSITION
      span.ja ポジション
      span.ja.required ※{{ errors.position }}
    input(type="text" id="position" placeholder="Point Guard  /  ポイントガード" v-model="position")
    p.inputs__count {{ positionCount }}/{{ MAX.position }}
  div.inputs__item.flex
    div.inputs__age
      label.inputs__label(for="age")
        span.en AGE
        span.ja 年齢
        span.ja.required ※{{ errors.age }}
      div.inputs__select
        select(id="age" required v-model="age")
          optgroup(label="")
            option(value="") Select
            option(value="-1") No Answer
            option(v-for="age in ages" :key="age" :value="age")
              span {{ age }}
    div.inputs__country
      label.inputs__label(for="country")
        span.en COUNTRY / REGION
        span.ja 国 / 地域
        span.ja.required ※{{ errors.country }}
      div.inputs__select
        select(id="country" required v-model="country")
          optgroup(label="")
            option(value="") Select
            option(v-for="country in COUNTRIES" :key="country" :value="country")
              span {{ country }}
  div.inputs__item
    label.inputs__label(for="message")
      span.en MESSAGE
      span.ja メッセージ
      span.ja.required ※{{ errors.message }}
    textarea(id="message" placeholder="Please write a message of support  /  届けたい応援メッセージをお書きください" rows="4" v-model="message")
    p.inputs__count {{ messageCount }}/{{ MAX.message }}
  div.inputs__item
    p.inputs__label
      span.en TERMS OF SERVICE
      span.ja 利用規約
      span.ja.required ※
    div.term
      div.term__content
        h3 【免責事項】
        p 投稿されたメッセージは内容を確認後に当サイトに掲載されます。必ずしも掲載されるとは限りませんのであらかじめご了承ください。
          br
          | 投稿いただいた内容は、東映株式会社（以下「弊社」）の裁量により適宜編集の上、宣伝に活用させていただく場合がございます。
          br
          | また、当WEBサイトへ表示するほか、作品公式サイト、作品公式Twitter、Instagramおよびその他の媒体で掲載・紹介する場合がございます。あらかじめご了承下さい。

        h3 【個人情報の保護について】
        p 投稿者の個人データは個人情報保護法にもとづき厳重に管理し、本投稿の目的以外で使用することはありません。
          br
          | 個人情報は、弊社にて厳重に管理し、正当な理由なく第三者への開示、譲渡及び貸与することは一切ありません。
          br
          | ただし、弊社との間で機密保持契約を締結した第三者に対し、この投稿に関する業務を委託する場合を除きます。
          br
          | 詳しくは弊社個人情報保護ポリシーをご覧ください。
          br
          a(href="https://www.toei.co.jp/policy/index.html" target="_blank" rel="noopener noreferrer") https://www.toei.co.jp/policy/index.html
  div.inputs__item 
    label.inputs__agree
      input(type="checkbox" v-model="agree")
      span I agree to the terms of service&nbsp;&nbsp;/&nbsp;&nbsp;利用規約に同意する
  div.inputs__item.button
    button.inputs__button(:disabled="disabled" v-show="!submitted")
      div
        p.en POST MESSAGE
        p.ja メッセージを投稿する
    div.submit__loader(v-show="submitted")
</template>

<script setup>
import { useForm, useField } from "vee-validate";
import { ref, watchEffect } from "vue";
import * as yup from "yup";
import COUNTRIES from "/public/assets/json/countries.json";

const props = defineProps({
  submitted: {type: Boolean, required: true},
  onSubmit: { type: Function, required: true },
});

const MAX = {
  name: 15,
  position: 15,
  message: 60,
};

const REQUIRED_MESSAGE = "Required fields　必須項目です"; // TODO: Confirm
const EXCEEDED_MESSAGE = "Character count exceeded　文字数が超過しています"; // TODO: Confirm
const PROHIBIT_LANGUAGE_MESSAGE = "Contains invalid characters　使用できない文字が含まれています";

const EXCLUDE_JA_EN_NUM_AND_SYMBOL = /^[a-zａ-ｚ0-9０-９ぁ-んァ-ヶー\u4E00-\u9FFF!-/:-@¥[-`{-~！-／：-＠［-｀｛-～、-〜"'・”’‘￥\s]*$/i

const countStrLength = (str) => {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    str[i].match(/[ -~]/) ? (count += 0.5) : (count += 1);
  }
  return count;
};

const checkIsExceededCharLimit = (str, max) => countStrLength(str) <= max;

const schema = yup.object({
  name: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(EXCLUDE_JA_EN_NUM_AND_SYMBOL, PROHIBIT_LANGUAGE_MESSAGE)
    .test("name", EXCEEDED_MESSAGE, (str) =>
      checkIsExceededCharLimit(str, MAX.name) 
    ),
  position: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(EXCLUDE_JA_EN_NUM_AND_SYMBOL, PROHIBIT_LANGUAGE_MESSAGE)
    .test("name", EXCEEDED_MESSAGE, (str) =>
      checkIsExceededCharLimit(str, MAX.position)
    ),
  age: yup.string().nullable().required(REQUIRED_MESSAGE),
  country: yup.string().required(REQUIRED_MESSAGE),
  message: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(EXCLUDE_JA_EN_NUM_AND_SYMBOL, PROHIBIT_LANGUAGE_MESSAGE)
    .test("name", EXCEEDED_MESSAGE, (str) =>
      checkIsExceededCharLimit(str, MAX.message)
    ),
  agree: yup.boolean().nullable(),
});

const { values, errors, handleSubmit } = useForm({
  initialValues: {
    name: "",
    position: "",
    age: "",
    country: "",
    message: "",
    agree: false,
  },
  validationSchema: schema,
});

const { value: name } = useField("name");
const { value: position } = useField("position");
const { value: age } = useField("age");
const { value: country } = useField("country");
const { value: message } = useField("message");
const { value: agree } = useField("agree");

const onSubmit = handleSubmit((values) => {
  props.onSubmit(values);
});

const ages = new Array(121).fill(null).map((_, i) => i);

const disabled = ref(false);
watchEffect(() => {
  const { agree, ...inputs } = values;
  disabled.value =
    Object.values(inputs).filter((v) => v === "").length > 0 || !agree;
});

const nameCount = ref(0);
const positionCount = ref(0);
const messageCount = ref(0);

watchEffect(() => {
  nameCount.value = Math.floor(countStrLength(name.value));
  positionCount.value = Math.floor(countStrLength(position.value));
  messageCount.value = Math.floor(countStrLength(message.value));
});
</script>

<style lang="scss" scoped>
.inputs {
  .flex {
    display: flex;
    @include mq("sp") {
      display: block;
    }
  }
}

.inputs__item {
  position: relative;
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }

  &:nth-last-child(-n + 2) {
    margin-top: 40px;
    @include mq("sp") {
      margin-top: 32px;
    }
  }

  label,
  select,
  input[type="checkbox"],
  button {
    cursor: pointer;
  }

  input[type="text"],
  select,
  textarea,
  button {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  input,
  select,
  textarea {
    background-color: #fff;
    &::placeholder {
      color: #83827f;
    }
    &:focus {
      outline: none;
    }
  }

  input[type="text"],
  select {
    height: 50px;
  }

  textarea {
    height: 110px;
  }

  input[type="text"],
  select,
  textarea {
    @include font("NotoSansJP-Regular");
    color: #222222;
    padding-left: 16px;
    padding-right: 30px;
    margin-top: 6px;
    line-height: 22px;
    border: 1px solid #83827f;
  }

  input[type="text"],
  textarea {
    font-size: 16px;
  }

  select {
    font-size: 13px;
  }

  select:invalid {
    color: #83827f;
  }

  select option {
    color: #222;
  }

  select option:first-child {
    color: #83827f;
  }

  input[type="checkbox"] {
    margin-right: 8px;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #83827f;
    width: 22px;
    height: 22px;
    padding: 0;
    border-radius: 0;
    position: relative;

    &::before {
      content: "";
      display: block;
      border-bottom: 3px solid #666464;
      border-left: 3px solid #666464;
      opacity: 0;
      height: 6px;
      width: 11px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:checked {
      &::before {
        opacity: 1;
      }
    }
  }

  textarea {
    padding-top: 16px;
    padding-bottom: 16px;
    resize: none;
  }
}

.inputs__select {
  position: relative;
  &::before {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid black;
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
  }
}

.inputs__age {
  width: 26.7857142857%;
  margin-right: 3.57142857143%;
  @include mq("sp") {
    width: 46.875%;
    margin-right: 0;
  }
}

.inputs__country {
  width: 69.6428571429%;
  @include mq("sp") {
    width: 100%;
    margin-top: 24px;
  }
}

.inputs__count {
  @include font("NotoSansJP-Medium");
  font-size: 12px;
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
}

.inputs__agree {
  @include font("NotoSansJP-Medium");
  display: flex;
  align-items: center;
  font-size: 13px;
  height: 22px;
  letter-spacing: 0;
  font-feature-settings: normal;

  @include mq("sp") {
    font-size: 12px;
  }
}

.inputs__label {
  height: 22px;

  .en {
    @include font("bebas-neue-pro-Bold");
    letter-spacing: 0.05em;
    font-size: 18px;
    margin-right: 6px;
    line-height: 22px;
  }

  .ja {
    @include font("NotoSansJP-Medium");
    font-size: 12px;
    letter-spacing: 0;
    font-feature-settings: normal;
    margin-right: 0.2em;
  }

  .required {
    color: #b70101;
  }
}

.inputs__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 74px;
  padding-top: 13px;
  padding-bottom: 21px;
  border: 1px solid #222;
  background-color: #222;
  color: #fff;
  text-align: center;
  cursor: pointer;

  @include mq("sp") {
    height: 64px;
    padding-top: 9px;
  }

  &:disabled {
    color: #222;
    background-color: transparent;
    cursor: auto;
  }

  .en {
    @include font("bebas-neue-pro-Bold");
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.04em;
    @include mq("sp") {
      font-size: 21px;
    }
  }

  .ja {
    @include font("NotoSansJP-Regular");
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0;
    font-feature-settings: normal;
    margin-top: 2px;
    @include mq("sp") {
      margin-top: 0;
    }
  }
}

.term {
  width: 100%;
  height: 222px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #fff;
  border: 1px solid #83827f;
  margin-top: 6px;
  overflow-y: hidden;
  position: relative;
  text-shadow: none;

  @include mq("sp") {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &__content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow-y: scroll;
    padding-left: 26px;
    padding-right: 34px;
    letter-spacing: 0;
    font-feature-settings: normal;

    @include mq("sp") {
      padding-left: 16px;
      padding-right: 16px;
    }

    h3 {
      @include font("NotoSansJP-Medium");
      margin-top: 20px;
      font-size: 13px;
      font-weight: 500;
      line-height: 23px;
      &:first-of-type {
        margin-top: 0;
      }
      @include mq("sp") {
        font-size: 11px;
        line-height: 22px;
      }
    }

    p {
      @include font("NotoSansJP-Regular");
      margin-top: 4px;
      font-size: 12px;
      line-height: 23px;
      &:first-of-type {
        margin-top: 0;
      }
      @include mq("sp") {
        font-size: 11px;
        line-height: 22px;
      }
    }

    a {
      border-bottom: 1px solid #222;
      padding-bottom: 0.01em;
      padding-left: 0.2em;
      padding-right: 0.2em;
      display: inline-block;
    }
  }
}

.submit__loader,.submit__loader:after {
  border-radius: 50%;
  width: 6em;
  height: 6em;
}
.submit__loader{
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.4em solid #000;
  border-right: 0.4em solid #000;
  border-bottom: 0.4em solid #000;
  border-left: 0.4em solid #fff;
  transform: translateZ(0);
  animation: loader 1.1s infinite linear;
}
@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
