<template lang="pug">
div.form__share
  p.form__message(id="flashing") SHARE “POST MESSAGE”
  ul.form__sns
    li.sns__item
      a.sns__link.sns(
        :href="lnUrl",
        target="_blank"
        rel="nofollow noopener noreferrer"
      )
        img(src="/assets/img/msg_sns_icon_line.svg", alt="line")
    li.sns__item
      a.sns__link.sns(
        :href="fbUrl",
        target="_blank"
        rel="nofollow noopener noreferrer"
      )
        img(src="/assets/img/msg_sns_icon_facebook.svg", alt="facebook")
    li.sns__item
      a.sns__link.sns(
        :href="twUrl",
        target="_blank"
        rel="nofollow noopener noreferrer"
      )
        img(src="/assets/img/msg_sns_icon_twitter.svg", alt="twitter")
</template>

<script setup>
import gsap from "gsap";
import { onMounted } from "vue";
import SNS_SHARE from '@/module/snsShare'

const props = defineProps({
  duration: { type: Number, required: true },
  ease: { type: String, required: true },
  repeatDelay: { type: Number, required: true },
});

const SHARE_TEXT = encodeURIComponent(`COURT SIDEから、応援メッセージを送りました。

湘北の選手たちや、映画『THE FIRST SLAM DUNK』に関わるすべての人たちへ。
`)
const SHARE_TAG = encodeURIComponent('SLAMDUNK,SLAMDUNKMOVIE,courtSD')
const SHARE_URL = encodeURIComponent('https://www.slamdunk-movie-courtside.jp/message/')

const lnUrl = `http://line.me/R/msg/text/?${SHARE_TEXT}%20${SHARE_URL}`
const fbUrl = `http://www.facebook.com/sharer.php?u=${SHARE_URL}`
const twUrl = `http://twitter.com/share?text=${SHARE_TEXT}&hashtags=${SHARE_TAG}&url=${SHARE_URL}`

onMounted(() => {
  gsap
    .fromTo(
      "#flashing",
      { opacity: 0 },
      {
        opacity: 1,
        duration: props.duration,
        ease: props.ease,
        yoyoEase: true,
      }
    )
    .repeat(-1)
    .repeatDelay(props.repeatDelay)
    .yoyo(true);
});


</script>

<style lang="scss" scoped>
.form__shre {
  text-align: center;
}

.form__message {
  @include font("neue-haas-grotesk-display-Roman");
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-align: center;

  @include mq("sp") {
    line-height: 23px;
    letter-spacing: 0.03em;
  }
}

.form__sns {
  display: flex;
  justify-content: space-between;
  margin: 15px auto 0 auto;
  width: 133px;

  @include mq("sp") {
    width: 136px;
    margin: 9px auto 0 auto;
  }

  img {
    width: 30px;
  }
}
</style>
