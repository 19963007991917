<template lang="pug">
h3.msg__copy.copy(ref="copy")
  picture
    source(media="(max-width: 750px)", srcset="/assets/img/msg_copy_sp.svg")
    img.copy__image(src="/assets/img/msg_copy.svg", width="607" height="101" alt="")

.msg__tutorial.tutorial(ref="tutorial")
  img.tutorial__image(src="/assets/img/msg_tutorial_img.svg", alt="")
  .tutorial__text
    p.text__main
      | Tap the banner to view profile
    p.text__sub
      | 横断幕をタップするとプロフィールが表示されます

.msg__contents(:class="{'is-active' : $store.state.msgActive}" ref="msgContents")

  .msg__postMsgBtn(:class="{'is-active' : $store.state.msgActive}" ref="postMsgBtn" @click="MsgFormSwitch")
    .postMsgBtn__main
      | POST MESSAGE
    .postMsgBtn__sub
      | メッセージを投稿する

  ul.contents__list.list(ref="msgContentsList")
    li.list__banner(ref="msgContentsBanner" @click="Modal" v-for="data, index in msgData" :data-message="data.message" :data-name="data.name" :data-position="data.position" :data-age="data.age" :data-country="data.country")
      img.banner__frame(src="/assets/img/msg_banner_frame.png", alt="")
      h3.banner__heading
        | {{ data.message }}

  .contents__viewMore(:class="{'is-active' : $store.state.msgActive}")
    .viewMore__btn(v-if="isViewMore" v-show="!isLoading" @click="ViewMore")
      | VIEW MORE
      img.viewMore__cross(src="/assets/img/msg_viewMore_cross.svg", alt="")
    .viewMore__loader(v-show="isLoading")

  .contents__modal(:class="{'is-active': isModal}")
    .modal__overlay.overlay(@click="Modal")
      .overlay__texture
    .modal__close.close(@click="Modal")
      span.close__btn
    .modal__banner.banner(ref="modalBanner")
      img.banner__frame(src="/assets/img/msg_banner_frame.png", alt="")
      h3.banner__heading
        | {{ banner.message }}
    dl.modal__profile.profile
      .profile__block.name
        dt.profile__title COURT&nbsp;NAME&nbsp;:&nbsp;
        dd.profile__disc {{ banner.name }}
      .profile__block.position
        dt.profile__title POSITION&nbsp;:&nbsp;
        dd.profile__disc {{ banner.position }}
      .profile__block.age
        dt.profile__title AGE&nbsp;:&nbsp;
        dd.profile__disc {{ banner.age }}
      .profile__block.country
        dt.profile__title COUNTRY&nbsp;/&nbsp;REGION&nbsp;:&nbsp;
        dd.profile__disc {{ banner.country }}
</template>

<script>
import axios from 'axios'
import gsap from 'gsap'

export default {
  data(){
		return{
      msgData: [],
      msgDataCount: 0,
      msgDataMax: 0,
      msgDataOutput: 0,
      banner: {
        message: "",
        name: "",
        position: "",
        age: "",
        country: ""
      },
      isModal: false,
      isViewMore: true,
      isLoading: false,
		}
	},
  mounted(){
    // メッセージファイルの総数取得
    axios
      .get('/api/json/messages.json?timestamp='+new Date().getTime())
      .then((response) => {
        this.msgDataMax = response.data.count
        // メッセージ取得
        this.GetMessage()
      })
  },
  methods:{
    textStyle(data){
      let count = 0
      for (let index = 0; index < data.dataset.message.length; index++) {
        data.dataset.message[index].match(/[ -~]/) ? count += 0.5 : count += 1
      }

      if(count > 0 && count <= 5){
        data.dataset.pattern = 1
      }else if(count > 5 && count <= 12){
        data.dataset.pattern = 2
      }else if(count > 12 && count <= 20){
        data.dataset.pattern = 3
      }else if(count > 20 && count <= 60){
        data.dataset.pattern = 4
      }
    },
    ViewMore(){
      // メッセージファイル番号、カウントアップ
      this.msgDataCount++

      // メッセージ取得ローディング開始
      this.isLoading = true

      // メッセージ取得
      this.GetMessage()
    },
    GetMessage(){
      const count = String(this.msgDataCount).padStart(3, '0')

      axios
        .get('/api/json/messages_'+count+'.json?timestamp='+new Date().getTime())
        .then((response) => {
          // メッセージ取得ローディング終了
          this.isLoading = false

          //　メッセージ配列に取得データ追加
          for (let index = 0; index < response.data.messages.length; index++) {
            this.msgData.push(response.data.messages[index])
          }

          this.$nextTick(() => {
            // テキストスタイル適応
            for (let index = this.msgDataOutput; index < this.$refs.msgContentsBanner.length; index++) {
              this.textStyle(this.$refs.msgContentsBanner[index])
            }

            // ビルドアニメーション
            this.Anime()

            // 横断幕の総数取得
            this.msgDataOutput = this.$refs.msgContentsBanner.length

            // メッセージコンテンツビルドチェック
            if(!this.$store.state.msgBuild) this.$store.state.msgBuild = true

            // msgDataCountがメッセージ総数に達した時ViewMore非表示
            if(this.msgDataMax === this.msgDataCount + 1){
              this.isViewMore = false
            }
          })
        })
    },
    Modal(e){
      this.$refs.modalBanner.dataset.pattern = e.currentTarget.dataset.pattern
      this.banner.message = e.currentTarget.dataset.message
      this.banner.name = e.currentTarget.dataset.name
      this.banner.position = e.currentTarget.dataset.position
      this.banner.age = e.currentTarget.dataset.age === '-1' ? 'No Answer' : e.currentTarget.dataset.age
      this.banner.country = e.currentTarget.dataset.country

      this.isModal = !this.isModal
    },
    Anime(){
      const msgHeading = document.querySelector('.msg__heading')
      // メッセージコンテンツ、アニメーション
      const gsapTL = gsap.timeline()
      const t = {
        _a: this.$store.state.msgBuild ? 0 : 2,
        _b: this.$store.state.msgBuild ? 0 : 1,
        _c: this.$store.state.msgBuild ? 0 : 2,
        _d: this.$store.state.msgBuild ? 0 : 2,
        _e: this.$store.state.msgBuild ? 0 : 1,
        _f: this.$store.state.msgBuild ? 0 : 1,
        _g: this.$store.state.msgBuild ? 0 : 3,
        _h: this.$store.state.msgBuild ? 0 : 1,
        _i: this.$store.state.msgBuild ? 0 : 1,
      }
      gsapTL
        .to(this.$refs.copy, {
          duration: t._a,
          delay: t._b,
          ease: 'sine.out',
          opacity: 1,
        })
        .to(this.$refs.copy, {
          duration: t._c,
          delay: t._d,
          ease: 'sine.out',
          opacity: 0,
        })
        .to(this.$refs.tutorial, {
          duration: t._e,
          ease: 'sine.out',
          opacity: 1,
        })
        .to(this.$refs.tutorial, {
          duration: t._f,
          delay: t._g,
          ease: 'sine.out',
          opacity: 0,
        })
        .to(msgHeading, {
          duration: t._h,
          ease: 'sine.out',
          opacity: 1,
        })

      let total = 0;
      for (const key in t) total += t[key]

      let delayTime = 0
      for (let index = this.msgDataOutput; index < this.$refs.msgContentsBanner.length; index++) {
        if(index % 3 === 0) delayTime += 0.2
        gsap.to(this.$refs.msgContentsBanner[index], {
          duration: t._i ,
          delay: total - 1 + delayTime,
          ease: 'sine.out',
          alpha: 1,
        })
      }

      total = total * 1000
      setTimeout(() => { this.$store.state.msgActive = true }, total)
    },
    MsgFormSwitch(){
      const msgOpen = document.querySelector('.msg__open')
      msgOpen.scrollTop = 0
      this.$store.state.msgPage = false
    }
  }
}
</script>

<style lang="scss" scoped>
$bannerWidthPc: 55.779816513761468%;
$bannerWidthSp: calc(100% - (30px * 2));

.msg__postMsgBtn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  right: 212px;
  bottom: 30px;
  padding: 11px 60px 14px;
  background-color: #141414;
  border-radius: 40px;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  transition-duration: 1000ms;
  transition-timing-function: $easeOutSine;
  @include mq("sp"){
    right: 20px;
    bottom: 52px;
    padding: 6px 46px 9px;
  }
  .postMsgBtn__main{
    @include font("bebas-neue-pro-Bold");
    font-size: 2.4rem;
    color: #FFFFFF;
    @include mq("sp"){
      font-size: 2rem;
    }
  }
  .postMsgBtn__sub{
    @include font("YuGothic");
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.6);
  }
}

.msg__postMsgBtn.is-active{
  opacity: 1;
}

.msg__copy{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 44px;
  left: 0;
  margin: auto;
  width: 607px;
  height: 101px;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  @include mq("sp"){
    top: 0;
    right: auto;
    bottom: 0;
    left: 24px;
    margin: auto 0;
  }
  .copy__image{
    @include mq("sp"){
      width: 219px;
      height: 127px;
    }
  }
}

.msg__tutorial{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 192px);
  height: 100%;
  background-color: rgba(17, 17, 17, 0.8);
  pointer-events: none;
  opacity: 0;
  @include mq("sp"){
    width: 100%;
    height: calc(100% - 40px);
  }
  .tutorial__image{
    margin-bottom: 15px;
    @include mq("sp"){
      width: 39px;
    }
  }
  .tutorial__text{
    color: #FAFAF1;
    text-align: center;
    .text__main{
      @include font("bebas-neue-pro_Middle");
      margin-bottom: 8px;
      font-size: 2.5rem;
      letter-spacing: 0.03em;
      @include mq("sp"){
        font-size: 2.3rem;
      }
    }
    .text__sub{
      @include font("NotoSansJP-Regular");
      font-size: 1rem;
    }
  }
}

.msg__contents{
  position: relative;
  left: 90px;
  padding-top: 240px;
  width: 100%;
  max-width: 84.007352941176471%;
  height: 100%;
  pointer-events: none;
  @include mq("sp"){
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 186px 0 24px 0;
    left: 24px;
    width: auto;
    max-width: none;
    min-width: 100%;
    height: 100%;
  }

  .contents__list{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    @include mq("sp"){
      height: 100%;
      writing-mode: vertical-lr;
    }
    .list__banner{
      display: inline-block;
      position: relative;
      margin-right: 28px;
      margin-bottom: 26px;
      width: calc(33.3333333333% - (28px * 2 / 3));
      cursor: pointer;
      opacity: 0;
      transition-duration: 1000ms;
      transition-timing-function: $easeOutSine;
      will-change: opacity;
      @include mq("sp"){
        margin-right: 16px;
        margin-bottom: 8px;
        width: auto;
        height: calc(33.3333333333% - (8px * 2 / 3));
        writing-mode: horizontal-tb;
      }
    }
    .list__banner:nth-last-child(-n+3){
      margin-bottom: 0;
      @include mq("sp"){
        margin-bottom: 8px;
      }
    }
    .list__banner:nth-of-type(3n){
      margin-right: 0;
      @include mq("sp"){
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
    .banner__frame{
      width: 100%;
      @include mq("sp"){
        width: auto;
        height: 100%;
      }
    }
    .banner__heading{
      @include font("HOT-GFKaishoStd-E");
      position: absolute;
      top: calc(50% - 1vw);
      left: 50%;
      display: block;
      padding: 0 28px;
      width: 100%;
      color: #FBFBFB;
      text-align: center;
      line-height: 1.5;
      word-break: break-all;
      transform: translate(-50%, -50%);
      @include mq("sp"){
        top: calc(50% - 1.2vh);
        padding: 0 16px;
      }
    }
    [data-pattern="1"] .banner__heading{
      font-size: 3.515625vw;
      letter-spacing: -0.05em;
      line-height: 1.2;
      @include mq("sp"){
        font-size: 5.072463768115942vh;
      }
    }
    [data-pattern="2"] .banner__heading{
      font-size: 2.34375vw;
      line-height: 1.1;
      @include mq("sp"){
        font-size: 3.985507246376811vh;
      }
    }
    [data-pattern="3"] .banner__heading{
      font-size: 1.875vw;
      letter-spacing: -0.07em;
      line-height: 1.25;
      @include mq("sp"){
        font-size: 2.717391304347826vh;
        letter-spacing: -0.05em;
      }
    }
    [data-pattern="4"] .banner__heading{
      font-size: 1.015625vw;
      line-height: 1.5;
      @include mq("sp"){
        font-size: 1.630434782608696vh;
        line-height: 1.3;
        letter-spacing: -0.05em;
      }
    }
  }

  .contents__viewMore{
    margin-top: 48px;
    padding-bottom: 74px;
    text-align: center;
    line-height: 1.5;
    opacity: 0;
    transition-duration: 1000ms;
    transition-timing-function: $easeOutSine;
    @include mq("sp"){
      margin-top: 0;
      padding-bottom: 0;
      padding-right: 24px;
    }
    .viewMore__btn{
      @include font("bebas-neue-pro");
      @include hoverLine();
      display: inline-block;
      position: relative;
      width: max-content;
      font-size: 1.9rem;
      cursor: pointer;
      @include mq("sp"){
        font-size: 1.5rem;
      }
    }
    .viewMore__cross{
      vertical-align: baseline;
      margin-left: 6px;
      @include mq("sp"){
        margin-left: 4px;
        width: 11px;
      }
    }
    .viewMore__loader,.viewMore__loader:after {
      border-radius: 50%;
      width: 6em;
      height: 6em;
    }
    .viewMore__loader{
      margin: 0 auto;
      font-size: 10px;
      position: relative;
      text-indent: -9999em;
      border-top: 0.4em solid #000;
      border-right: 0.4em solid #000;
      border-bottom: 0.4em solid #000;
      border-left: 0.4em solid #fff;
      transform: translateZ(0);
      animation: loader 1.1s infinite linear;
    }
    @-webkit-keyframes loader {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes loader {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
  .contents__viewMore.is-active{
    opacity: 1;
  }

  .contents__modal{
    @include transition();
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 192px);
    height: 100%;
    pointer-events: none;
    opacity: 0;
    z-index: 2;
    @include mq("sp"){
      width: 100%;
    }
    .modal__overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #222222, $alpha: 0.95);
      .overlay__image{
        position: absolute;
        top: 0;
        right: 0;
        width: 30.78125vw;
        height: auto;
        @include mq("sp"){
          width: 46.666666666666667vw;
        }
      }
      .overlay__texture{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/assets/img/msg_modal_texture.png);
        background-repeat: repeat;
        background-size: contain;
      }
    }
    .modal__close{
      display: flex;
      justify-content: flex-end;
      position: relative;
      margin-bottom: 12px;
      width: $bannerWidthPc;
      height: 32px;
      cursor: pointer;
      @include mq("sp"){
        width: $bannerWidthSp;
        height: 23px;
      }
      .close__btn{
        position: relative;
        right: 0;
        width: 44px;
        height: 32px;
        @include mq("sp"){
          width: 32px;
          height: 23px;
        }
        &:before,&:after{
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 2px;
          height: 50px;
          background-color: #F8F8F8;
          @include mq("sp"){
            height: 38px;
          }
        }
        &:before{
          transform: rotate(55deg);
        }
        &:after{
          transform: rotate(-55deg);
        }
      }
    }

    .modal__banner{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: $bannerWidthPc;
      @include mq("sp"){
        width: $bannerWidthSp;
      }
      .banner__frame{
        width: 100%;
      }

      .banner__heading{
        @include font("HOT-GFKaishoStd-E");
        position: absolute;
        margin-bottom: 3.7vw;
        padding: 0 60px;
        width: 100%;
        text-align: center;
        word-break: break-all;
        color: #FBFBFB;
        @include mq("sp"){
          padding: 0 24px;
        }
      }
      &[data-pattern="1"] .banner__heading{
        font-size: 6.875vw;
        letter-spacing: -0.05em;
        @include mq("sp"){
          font-size: 12.266666666666667vw;
        }
      }
      &[data-pattern="2"] .banner__heading{
        font-size: 5vw;
        line-height: 1.2;
        @include mq("sp"){
          font-size: 10.133333333333333vw;
          letter-spacing: -0.05em;
        }
      }
      &[data-pattern="3"] .banner__heading{
        font-size: 3.75vw;
        line-height: 1.25;
        @include mq("sp"){
          font-size: 6.933333333333333vw;
          letter-spacing: -0.05em;
        }
      }
      &[data-pattern="4"] .banner__heading{
        font-size: 2.1875vw;
        line-height: 1.5;
        @include mq("sp"){
          font-size: 4.266666666666667vw;
        }
      }
    }

    .modal__profile{
      position: relative;
      margin-top: 24px;
      width: $bannerWidthPc;
      color: #DADADA;
      @include mq("sp"){
        margin-top: 12px;
        width: $bannerWidthSp;
      }
      .profile__block{
        @include font("Factoria Demi");
        margin-bottom: 8px;
        @include mq("sp"){
          margin-bottom: 4px;
        }
      }
      .profile__block:last-child{
        margin-bottom: 0;
      }
      .profile__title{
        display: inline-block;
        font-size: 1.5rem;
        @include mq("sp"){
          font-size: 1.2rem;
        }
      }
      .profile__disc{
        display: inline-block;
        font-size: 1.7rem;
        @include mq("sp"){
          font-size: 1.3rem;
        }
      }
      .name .profile__disc{
        @include font("HOT-GFKaishoStd-E");
        letter-spacing: -0.05em;
      }
      .position .profile__disc{
        @include font("HOT-GFKaishoStd-E");
        letter-spacing: -0.05em;
      }
    }

  }
  .contents__modal.is-active{
    pointer-events: auto;
    opacity: 1;
  }
}
.msg__contents.is-active{
  pointer-events: auto;
}
</style>